import React from 'react';
import './dashboard-header.scss';
import ClientDashboard from "../dashboard/clientDashboard/ClientDashboard";

const DashboardHeader = ({...props}) => {
  return (
    <>
      <div className="dashHeader-wrapper">
        <div className="dashHeader-title">
          <h1>DASHBOARD</h1>
          <div>{props.client.name.toUpperCase()}  ID:{props.client.id}</div>
        </div>

        <div className="dashHeader-control">
          <button
            onClick={props.onClose}
          >Pop-out window
          </button>

        </div>
      </div>

      {props.show ? <ClientDashboard
        modal={props.show}
        setModal={props.onClose}
        client={props.client}
      /> : ''}

    </>
  );
};


export default DashboardHeader;
