import React from 'react';
import './dash-nav.scss';
import {CInputGroup, CInput } from "@coreui/react";
import fn from 'src/utils/Helper';
import CIcon from '@coreui/icons-react'

const DashNav = ({...props}) => {

  const onDateSelectionClick = (ev) => {
    //console.log(ev.target.value);
    const newDate = new Date(ev.target.value);
    props.setDate(newDate);
  }

  return (
    <>
      <div
        style={props?.localStyle}
        className="DNav-wrapper">

        <div className="DNav">
          <div className="DNav-nav">
            <CInputGroup className="DNav-nav-group">
              <div>
                Date Range
              </div>
              <CInput
                className="DNav-nav-input-date"
                type="date"
                value={fn.getMonthRanges(props.date).start}
                onChange={onDateSelectionClick}
              />
              <CInput
                className="DNav-nav-input-date"
                type="date"
                value={fn.getMonthRanges(props.date).end}
                onChange={onDateSelectionClick}
                />
            </CInputGroup>
          </div>
          {/* NAV TO SWITCH LABEL->GRAPH */}
          {/*<div className="DNav-nav-icon-wrapper">
            <CIcon
              className={'DNav-nav-icon ' + (props.graphsPage ? 'icon-active' : '') }
              size="lg" content={cilGraph}
              onClick={onSetGraphsPage}
            />
            <CIcon
              className={'DNav-nav-icon ' + (!props.graphsPage ? 'icon-active' : '') }
              size="lg" content={cilChart}
              onClick={onSetGraphsPage}
            />
          </div>*/}

        </div>

        <div className="DNav-Pagination-wrapper">
          <div
            onClick={ ()=>{ props.setDate((dt) => {
              const lastMonthLength = (new Date(dt.getFullYear(), dt.getMonth(),0)).getDate();
              const newDate = dt.getTime() - (1000 * 86400 * lastMonthLength);
              return new Date(newDate);
            }) } }
            className="DNav-Pagination-prev">
            Previous</div>
          <div className="DNav-Pagination-range">{fn.getMonthRanges(props.date).start} - {fn.getMonthRanges(props.date).end}</div>
          <div
            onClick={()=>{ props.setDate((dt) => {
              const currentTimestamp = Date.now();
              const navigationTimestamp = dt.getTime();
              if(navigationTimestamp > currentTimestamp) return dt;
              const lastMonthLength = (new Date(dt.getFullYear(), dt.getMonth(),0)).getDate();
              const newDate = dt.getTime() + (1000 * 86400 * lastMonthLength);
              return new Date(newDate);
            })}}
            className="DNav-Pagination-next">
            Next</div>
        </div>
      </div>
    </>
  );
};


export default DashNav;
