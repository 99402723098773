import React from 'react';
import LineGraphCustom from "../../components/DashGraph/LineGraphCustom";


const styles = {
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.85)',
    color: '#fff',
    zIndex: 10000,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    height: '100vh'
  },
  box: {
    padding: '1rem',
    minWidth: '75%',
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '25px',
    position: 'relative',
  },
  close: {
    color: '#000',
    fontSize: '2rem',
    position: 'absolute',
    right: 20,
    top: 0,
    cursor: 'pointer',
  }
}


const GlucoseDayGraph = props => {
  return (
    <>
      {props.isModal
        ?
        (
          <div style={styles.modal}>
            <div style={styles.container}>
              <div style={styles.box}>

                <div
                  onClick={(ev)=>props.setIsModal(false)}
                  style={styles.close}>&#10005;</div>

                <div>
                  <LineGraphCustom
                    title={'Blood Sugar Levels And Time In Range (%)'}
                    xLabel={'HOURS'}
                    yLabel={'RANGE'}
                    show={true}
                    tirDataSet={props.dayData}
                  />
                </div>

              </div>
            </div>
          </div>
        )
        : ''}
    </>
  );
};

GlucoseDayGraph.propTypes = {

};

export default GlucoseDayGraph;

