import React from 'react';
import { Chart as ChartJS, Tooltip, Legend } from 'chart.js';
import {Line} from "react-chartjs-2";
import {CategoryScale, LinearScale, PointElement, LineElement, Title} from "chart.js";
import fn from "src/utils/Helper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const tol = 0.02;

ChartJS.defaults.elements.line.borderWidth = 2;
//ChartJS.defaults.elements.point.pointStyle = 'triangle';
ChartJS.defaults.elements.point.radius = 0;
//console.log(ChartJS.defaults);


const LineGraph = ({...props}) => {
    //console.log(props);
  const dataSet = {
    labels: props.data.lab,
    datasets: [
        {
          label: props.data.lab.map(l => l),
          data: props.data.data.map(d => d),
          backgroundColor: props.data.backgroundColor,
          borderColor: props.data.borderColor,

        }
    ]
    }

  const opt = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        //position: 'top',
      },
      title: {
        display: true,
        text: props.title,
        color: '#000',
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      lineWidth: 1,
    },
    scales: {
      y: {
        title: {
          display: true,
          text: props.yLabel ? props.yLabel : 'UNITS',
          color: '#000',
          font: {
            size: 12,
            weight: 'bold'
          }
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value;
          }
        },
        min: fn.getRange(props.data.data, tol),
        max: fn.getRange(props.data.data, tol, false)
      },
      x: {
        title: {
          display: true,
          text: props.xLabel ? props.xLabel : 'SOME UNITS',
          color: '#000',
          font: {
            size: 12,
            weight: 'bold'
          }
        }
      }
    }
  };


  return (
    <>
      <div className="chart-wrapper">
          {(dataSet.datasets.length !== 0) ?
           (<Line
            options={opt}
            data={dataSet} type={'line'}/>) :
            ''}
      </div>
    </>
  );
};


export default LineGraph;
