import React from 'react';
import { Chart as ChartJS, Tooltip, Legend } from 'chart.js';
import {Line} from "react-chartjs-2";
import {CategoryScale, LinearScale, PointElement, LineElement, Title} from "chart.js";
import fn from "src/utils/Helper";
import {Filler} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const tol = 0.1;

ChartJS.defaults.elements.line.borderWidth = 2;
//ChartJS.defaults.elements.point.pointStyle = 'triangle';
ChartJS.defaults.elements.point.radius = 0;
//console.log(ChartJS.defaults);


const LineGraphCustom = ({...props}) => {

  // console.log('props',props);

  const tier = {
      lab: props.tirDataSet.lab.map(l => l),
      data: props.tirDataSet.data.map(d => d),
      backgroundColor: '#a20',
      borderColor: '#a20',
    };

  const range = {
    id: 'range',
    label: 'range',
    data: tier.data.map(() => 10),
    lab: tier.data.map(() => ''),
    borderColor: 'rgba(255, 2, 5, 0)',
    backgroundColor: 'rgba(255, 2, 5, 0.5)',
    fill: 'end',
  };

  const bottomRange = {
    id: 'rangeBottom',
    label: 'rangeBottom',
    data: tier.data.map(() => 3.9),
    lab: tier.data.map(() => ''),
    borderColor: 'rgba(255, 2, 5, 0)',
    backgroundColor: 'rgba(255, 2, 5, 0.5)',
    fill: 'start',
  };
  //console.log(range)

  const dataSet = {
    labels: props.tirDataSet.lab,
    datasets: [
      tier,
      range,
      bottomRange,
    ]
  };

  //console.log(dataSet);

  const opt = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        //position: 'top',
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        text: props.title,
        color: '#000',
        font: {
          size: 16,
          weight: 'bold'
        }
      },
      lineWidth: 1,
      intersection: false,
    },
    scales: {
      y: {
        title: {
          display: true,
          text: props.yLabel ? props.yLabel : 'UNITS',
          color: '#000',
          font: {
            size: 12,
            weight: 'bold'
          }
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value;
          }
        },
        min: 1.9,
        max: 12,
      },
      x: {
        title: {
          display: true,
          text: props.xLabel ? props.xLabel : 'SOME UNITS',
          color: '#000',
          font: {
            size: 12,
            weight: 'bold'
          }
        }
      }
    }
  };


  return (
    <>
      <div className="chart-wrapper">
        {(dataSet.datasets.length !== 0) ?
          (<Line
            options={opt}
            data={dataSet} type={'line'}/>) :
          ''}
      </div>
    </>
  );
};


export default LineGraphCustom;
