import React, {useState} from 'react';
import './client-view.scss';
import {OTSession,OTPublisher, OTStreams, OTSubscriber} from "opentok-react";
import '@opentok/client';
import cf from '../../config';
import phone from "../../assets/msw/ext/phone.svg";
import video from "../../assets/msw/ext/video.svg";
import videoSlash from "../../assets/msw/ext/video-slash.svg";
import micSlash from "../../assets/msw/ext/microphone-slash.svg";
import mic from "../../assets/msw/ext/microphone.svg";
import {useEffect} from "react";
import logo from 'src/assets/msw/msw-logo.png';

const ClientView = ({...props}) => {
  const [connection, setConnection] = useState('Connecting');
  const [error, setError] = useState(null);
  const [stream, setStream] = useState(null);
  const [session, setSession] = useState(null);
  const [subscribers, setSubscribers] = useState([]);
  const [publishVideo, setPublishVideo] = useState(true);
  const [videoChat, setVideoChat] = useState(null);
  const [muted, setMuted] = useState(null);

  const [isUserAcceptedTC, setIsUserAcceptedTC] = useState(false);

  useEffect(()=>{
    const controller = new AbortController();
    if(window.location.search){
      const idx = window.location.search.search('id=');
      const id = window.location.search.slice(idx + 3);
      fetch(`${cf.API_BASE}/${cf.videoChatEndpoint}/${id}`,{
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cf.TOKEN
        }
      }).then(res => res.json())
        .then(res => {
          //console.log(res.video_chat);
          setVideoChat(res.video_chat);
        }).catch(err => {
          console.log(err);
      })
    }

    return () => {
      controller.abort();
    }

  },[])

  const sessionEventHandlers = {
    sessionConnected: (ev) => {
      //console.log(ev);
      setSession(ev);
      setConnection( 'Connected' );
    },
    sessionDisconnected: () => {
      setConnection( 'Disconnected' );
    },
    sessionReconnected: () => {
      setConnection(  'Reconnected' );
    },
    sessionReconnecting: () => {
      setConnection(  'Reconnecting' );
    },
  };

  const publisherEventHandlers = {
    accessDenied: () => {
      console.log('User denied access to media source');
    },
    streamCreated: (ev) => {
      setStream(ev);
      ev.stream.setVideoDimensions(320, 240);
      console.log('Publisher stream created');
    },
    streamDestroyed: ({ reason }) => {
      console.log(`Publisher stream destroyed because: ${reason}`);
    },
    audioLevelUpdated: (ev) => {
      //console.log(ev);
    },
    setAudioVolume(value) {

    }

  };

  const subscriberEventHandlers = {
    videoElementCreated: (ev) => {
      //console.log(ev);
      /*const sub = subscribers;
      sub.push(ev.target);
      setSubscribers(sub);*/
    },
    videoEnabled: () => {
      console.log('Subscriber video enabled');
    },
    videoDisabled: () => {
      console.log('Subscriber video disabled');
    },
  };

  const onSessionError = (err) => {
    console.log(err);
    if(err){
      //history.push('/client-list');
      //history.push('/')
      // TODO ERROR display notification to the user
    }
    setError(err);
  };

  const onPublish = () => {
    console.log('Publish Success');
  };

  const onPublishError = error => {
    setError( error );
  };

  const onSubscribe = () => {
    /*const children = sub.current.childNodes[0].childNodes.length;
    const height = (iframe.current.clientHeight - 62)/children;
    sub.current.childNodes[0].childNodes.forEach(node => {
      node.childNodes[0].style.height = `${height}px`;
    });*/

    console.log('Subscribe Success');
  };

  const toggleArchiving = () => {
    /*if(!archiveVideo){
      fetch(`${cf.API_BASE}/${cf.videoChatEndpoint}/${videoChat.videoChat.id}/archives`, {
        method: 'POST',
        headers: {
          // Admin token
          Authorization: 'Bearer ' + videoChat.bearerToken
        }
      }).then(res => res.json())
        .then(res => {
          console.log(res, 'Archiving started');
          setArchiveVideo(true);
        })
    }else {
      // STOP Archiving
    }*/

  }
  const endCall = () => {
    //console.log(session)
    if(session) session.target.disconnect();
  }

  const togglePublisherMic = () => {
    if(stream){
      //console.log(stream);
      stream.target.publishAudio(muted);
      setMuted(()=>!muted);
      //session.target.forceMuteStream(stream.stream);
      //console.log(stream.stream);
    }

  }

  const onSubscribeError = error => {
    setError( error );
  };

  const toggleVideo = () => {
    setPublishVideo(()=>!publishVideo);
    //console.log(publishVideo);
  };

  const onArchiveStarted = () => {

  }


  return (
    <>

      {(isUserAcceptedTC) ?
        (
          (videoChat) ?
            ( <div className={'ClientView-wrapper'}>
              <div className={'ClientView'}>

                <did className="ClientView-logo" >
                  <img src={logo} alt={'image logo'}/>
                </did>

                <div className={'ClientView-screen'}>

                  <OTSession
                    apiKey={cf.API_KEY}
                    sessionId={videoChat?.vonage_session ?? ' '}
                    token={videoChat?.token ?? ' '}
                    onError={onSessionError}
                    eventHandlers={sessionEventHandlers}
                    onArchiveStarted={onArchiveStarted}
                  >

                    <div className={'ClientView-screen-publisher-wrapper'}>

                      <div>
                        <OTPublisher
                          className={'ClientView-screen-publisher'}
                          properties={{
                            publishVideo,
                            width: 320, height: '100%',
                            insertMode: 'append',
                            showControls: false,
                            fitMode: 'contain',
                          }}
                          onPublish={onPublish}
                          onError={onPublishError}
                          eventHandlers={publisherEventHandlers}
                        />
                      </div>
                    </div>

                    <div className={'ClientView-screen-publisher-controls'}>
                      <button
                        onClick={endCall}
                        className={'endCall'}
                      >
                        <img src={phone} alt={''}/>
                      </button>
                      <button id="videoButton" onClick={toggleVideo}>
                        {(publishVideo) ?
                          (<img src={video} alt={''}/> ) :
                          (<img src={videoSlash} alt={''}/> )}
                      </button>
                      <button id="soundButton" onClick={togglePublisherMic}>
                        {(muted) ?
                          (<img src={micSlash} alt={''}/> ) :
                          (<img src={mic} alt={''}/> )}
                      </button>
                    </div>

                    <div /*ref={sub}*/ className={'ClientView-screen-streams'}>

                      <OTStreams>
                        <OTSubscriber
                          className={'ClientView-screen-subscriber'}
                          properties={{
                            insertMode: 'append',
                            width: '100%', height: '100%',
                            showControls: true,
                            fitMode: 'cover',
                          }}
                          onSubscribe={onSubscribe}
                          onError={onSubscribeError}
                          eventHandlers={subscriberEventHandlers}
                        />
                      </OTStreams>

                    </div>


                  </OTSession>

                </div>
              </div>
            </div>) :
            ''
        )
        :
        (
          <div
            style={{position: 'fixed', zIndex: '10000',left: 0, right: 0, top: 0, bottom:0, backgroundColor: 'rgba(0,0,0,0.75)'}}>

            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100vh'}}>
              <div style={{backgroundColor: '#fff', color: '#000', borderRadius: '15px', padding: '3rem 2rem 3.5rem 2rem'}}>
                <div>I understand that this call will be recorded for training and monitoring purposes</div>
                <div style={{margin: '1rem', position: 'relative'}}>
                  <button
                    onClick={()=>setIsUserAcceptedTC(true)}
                    style={{position: 'absolute', right: 0, border: '1px solid #000', padding: '0.5rem', borderRadius: '5px', color: '#fff', backgroundColor: '#000', cursor: 'pointer'}}>Accept and join call</button>
                </div>
              </div>
            </div>
          </div>
        )}


    </>
  );
};


export default ClientView;
