import moment from "moment";

class Helper {

  getTime(timestamp) {
    const date = new Date(timestamp)
    return {
      hour: ('0' + date.getHours()).slice(-2),
      minutes: ('0' + date.getMinutes()).slice(-2),
    }
  }

  getTimestamp(date) {
    return Date.parse(date);
  }

  isAfter(date1, date2) {
    if (this.getTimestamp(date1) > this.getTimestamp(date2)) return true;
    else return false;
  }

  toMinutes(timestamp, toString = true, type = 'min') {
    let minutes = 0;
    if (type === 'min') minutes = (timestamp / 1000) / 60;

    if (toString) return minutes.toFixed(0);
    else return minutes;
  }

  findAverageFromSetsOfArrays(data, name) {
    const items = [];
    data.forEach(item => {
      items.push(item[name].length);
    });
    let avg = 0;
    items.forEach(n => {
      avg += n;
    });
    return Math.round(avg / data.length);
  }

  #getMin(arr, margin = 0) {
    const min = Math.floor(Math.min(...arr));
    if (margin) {
      const minV = Math.min(...arr);
      let tolerance = minV * margin;
      if (Math.abs(tolerance) < 1) tolerance = 1;
      return Math.floor((minV - tolerance));
    }
    //console.log(min)
    return min;
  }

  #getMax(arr, margin = 0) {
    const max = Math.ceil(Math.max(...arr));
    if (margin) {
      const maxV = Math.max(...arr);
      let tolerance = maxV * margin;
      if (Math.abs(tolerance) < 1) tolerance = 1;
      return Math.floor((maxV + tolerance));
    }
    //console.log(max);
    return max;
  }

  /*getChartRange(data, isMin = true){
    const all = [];
    if(!data) return;
    data.forEach(dataSet => {
      dataSet.data.forEach(n => {
        all.push(n);
      })
    });
    if(isMin) return this.#getMin(all);
    else return this.#getMax(all);
  }*/

  getRange(data, margin = 0, isMin = true) {
    const all = [];
    data.forEach(n => {
      if (n) all.push(Math.round(n));
      //else all.push(0);
    })
    if (isMin) return this.#getMin(all, margin);
    else return this.#getMax(all, margin);
  }

  #genDigit(notString = true) {
    if (notString) return Math.floor(Math.random() * 10 + 1);
    else return (Math.floor(Math.random() * 10 + 1)).toString(10);
  }

  #genChar(min = 65, max = 122) {
    let rnd = 0;
    do {
      rnd = Math.floor(Math.round(Math.random() * (max - min)) + min);
    } while (rnd < 90 && rnd > 96);

    return String.fromCharCode(rnd);
  }

  uuId(len = 30) {
    let rnd = '';
    for (let i = 0; i < len; i++) {
      rnd += this.#genChar();
    }
    return rnd;
  }

  filterProperUser(users = [], id = null) {
    return users.filter(user => user?.participant?.id === id);
  }

  getTimeInMinutes(time) {
    let min = (Math.floor(time / 60)).toString();
    let sec = (time - (min * 60)).toString();
    if (min.length < 2) min = min.padStart(2, '0');
    if (sec.length < 2) sec = sec.padStart(2, '0');
    return `${min}m ${sec}s`;
  }

  getToday(dt = null, asString = true) {
    const today = Date.now();
    if (asString) {
      let date = null;
      if (dt === null) date = new Date();
      else date = new Date(dt);
      const year = (date.getFullYear()).toString();
      const month = ((date.getMonth() + 1).toString()).padStart(2, '0');
      const day = (date.getDate().toString()).padStart(2, '0');
      console.log(`${year}-${month}-${day}`);

    } else {
      return today;
    }
  }

  dateFromMsToString(timestamp) {
    return new Date(timestamp);
  }

  dateToApiformat(timestamp) {
    const date = new Date(timestamp);
    const year = (date.getFullYear()).toString();
    const month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    const day = (date.getDate().toString()).padStart(2, '0');
    //console.log(`${year}-${month}-${day}`);
    return `${year}-${month}-${day}`;

  }

  filterDataByDate(data, min, max, isTimestamp = false) {
    let start, end;
    if (!isTimestamp) {
      start = this.getTimestamp(min);
      end = this.getTimestamp(max);
    } else {
      start = min;
      end = max;
    }

    return data.filter(dt => {
      const st = this.getTimestamp(dt.created_at);
      const ed = this.getTimestamp(dt.created_at);
      return ((st >= start) && (st <= end) && (ed >= start) && (ed <= end));
    })
  }

  getAverage(data = [], isObjectIn = false) {
    if (data.length === 0) return 0;
    let sum = 0;
    if (!isObjectIn) {
      data.forEach(n => {
        if ((typeof n) === 'number') sum += n;
        else {
          //const no = parseFloat(n);
          sum += n;
        }
      });
    } else {
      data.forEach(n => {
        if ((typeof n) === 'number') sum += n.value;
        else {
          const no = parseFloat(n.value);
          sum += no;
        }
      });
    }
    const avg = (sum / data.length);
    if ((typeof avg) === 'number') return avg;
    else return 'n/a';
  }

  getWeeksBack(weeks = 1) {
    const date = Date.now();
    const msec = (weeks * (1000 * 86400 * 7));
    const prevDate = date - msec;
    return this.dateToApiformat(prevDate);
  }

  getWeeksForward(weeks = 1) {
    const date = Date.now();
    const msec = (weeks * (1000 * 86400 * 7));
    const prevDate = date + msec;
    return this.dateToApiformat(prevDate);
  }

  getDaysBack(days = 1, ms = false) {
    const date = Date.now();
    const msec = (days * (1000 * 86400 * 1));
    const prevDate = date - msec;
    if (!ms) return prevDate;
    return this.dateToApiformat(prevDate);
  }

  getDaysForward(days = 1, ms = false) {
    const date = Date.now();
    const msec = (days * (1000 * 86400 * 1));
    const nextDate = date + msec;
    if (!ms) return nextDate;
    return this.dateToApiformat(nextDate);
  }

  getDaysFromNow(dateString) {
    const date = new Date(dateString);
    const now = Date.now();
    const ms = date.getTime();
    return Math.floor((now - ms) / (1000 * 86400));
  }

  getDay(no) {
    if (no === 0) return 'Sun';
    if (no === 1) return 'Mon';
    if (no === 2) return 'Tue';
    if (no === 3) return 'Wed';
    if (no === 4) return 'Thu';
    if (no === 5) return 'Fri';
    if (no === 6) return 'Sat';
    if (no < 0) return '+';
  }

  getAvg(arr) {
    const tmp = [];
    arr.forEach(a => {
      if (a.value) tmp.push(a.value)
    });
    let sum = 0;
    tmp.forEach(t => sum += t);
    return sum / tmp.length;
  }

  getAvgFromArray(arr) {
    const tmp = [];
    arr.forEach(a => {
      if (a) tmp.push(a)
    });
    let sum = 0;
    tmp.forEach(t => sum += t);
    return sum / tmp.length;
  }

  getMax(arr, tol = 0) {
    //console.log(arr, 'arr')
    const tmp = [];
    arr.forEach(a => {
      if (a.value) tmp.push(parseFloat(a.value));
    });
    const max = Math.max(...tmp);
    let band = max * tol;
    if (band < 1) band = 1;
    return max + band;
  }

  getMin(arr, tol = 0) {
    const tmp = [];
    arr.forEach(a => {
      if (a.value) tmp.push(parseFloat(a.value));
    });
    const min = Math.min(...tmp);
    let band = min * tol;
    if (band < 1) band = 1;
    return min + band;
  }

  getHigher(val1, val2) {
    if (val1 > val2) return val1;
    else if (val2 > val1) return val2;
    return val1;
  }

  // improved - using 5 weeks format
  getMonthRanges5(date, nutritionWeek = 0) {
    // console.log('date',date);
    const mm = moment(date);
    const firstDayOfTheMonth = mm.set("date", 1).set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0);
    const daysInMonth = moment(firstDayOfTheMonth).daysInMonth();
    let start = ((daysInMonth > (nutritionWeek * 7)) ? moment(firstDayOfTheMonth).add(7 * nutritionWeek, "days") : moment(firstDayOfTheMonth).set("date", daysInMonth));
    let end = ((daysInMonth > ((nutritionWeek + 1) * 7)) ? moment(firstDayOfTheMonth).add(7 * (nutritionWeek + 1), "days") : moment(firstDayOfTheMonth).set("date", daysInMonth));
    //console.log('start', start.format('YYYY-MM-DD'), 'end', end.format('YYYY-MM-DD'));
    return {
      start: firstDayOfTheMonth.format('YYYY-MM-DD'),
      end: moment(firstDayOfTheMonth).set("date", daysInMonth).format('YYYY-MM-DD'),
      lastDay: daysInMonth,
      startWeek: start.format('YYYY-MM-DD'),
      endWeek: end.format('YYYY-MM-DD'),

    };
  }

  // to be removed when no code depends on
  getMonthRanges(date, nutritionWeek = 0) {
    //console.log(date.toISOString(), date.getMonth());

    // console.log('date',date, 'nutritionWeek', nutritionWeek);

    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const year = end.getFullYear();
    const month = (end.getMonth() + 1).toString().padStart(2, '0');
    const day = end.getDate().toString().padStart(2, '0');

    const startDate = new Date(`${year}-${month}-01`);
    let tmp = (startDate.getTime()) + (1000 * 86400 * 7 * (nutritionWeek + 1));
    if (nutritionWeek === 4) tmp = end;

    const ewD = new Date(tmp);

    const start = new Date(startDate.getTime() + (1000 * 86400 * 7 * nutritionWeek));

    return {
      start: `${year}-${month}-01`,
      end: `${year}-${month}-${day}`,
      lastDay: day,
      startWeek: `${start.getFullYear()}-${(start.getMonth() + 1).toString().padStart(2, '0')}-${(start.getDate()).toString().padStart(2, '0')}`,
      endWeek: `${year}-${month}-${(ewD.getDate()).toString().padStart(2, '0')}`

    };

  }

  day(dateString) {
    const date = new Date(dateString);
    return date.getDate();
  }

  dayOfTheWeek(dateString) {
    const date = new Date(dateString);
    return date.getDay();
  }

  hour(dateString) {
    const date = new Date(dateString);
    return date.getHours();
  }

  min(dateString) {
    const date = new Date(dateString);
    return date.getMinutes();
  }


  foodType(time, m) {
    const hour = this.hour(time);
    const min = this.min(time);
    if (hour >= m.breakfast.start.hour && hour <= m.breakfast.end.hour && min >= m.breakfast.start.min && min <= m.breakfast.end.min) {
      return 'breakfast';
    }
    if (hour >= m.lunch.start.hour && hour <= m.lunch.end.hour && min >= m.lunch.start.min && min <= m.lunch.end.min) {
      return 'lunch';
    }
    if (hour >= m.dinner.start.hour && hour <= m.dinner.end.hour && min >= m.dinner.start.min && min <= m.dinner.end.min) {
      return 'dinner';
    }
    if (hour >= m.supper.start.hour && hour <= m.supper.end.hour && min >= m.supper.start.min && min <= m.supper.end.min) {
      return 'supper';
    }
    return 'snack';
  }

  getMonthLength(date, current = 1) {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + current, 0);
    return lastDay.getDate();
  }

  getUserFriendlyData(string) {
    const d = new Date(string);
    return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getDate().toString().padStart(2, '0')} - ${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}`
  }

  getTransformedData(string) {
    const d = new Date(string);
    return `${d.getFullYear()}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getDate().toString().padStart(2, '0')}`;
  }

  getDayOfTheWeek(dateString) {
    return (new Date(dateString)).getDay();
  }

  transformDaySunSatToMonSun(index) {
    switch (index) {
      case 0:
        return 6;
      default:
        return index - 1;
    }

  }


}

const meals = {
  breakfast: {
    start: {
      hour: 6, min: 0
    },
    end: {
      hour: 10, min: 59
    }
  },
  lunch: {
    start: {
      hour: 11, min: 0
    },
    end: {
      hour: 15, min: 59
    }
  },
  dinner: {
    start: {
      hour: 16, min: 0
    },
    end: {
      hour: 18, min: 59
    }
  },
  supper: {
    start: {
      hour: 19, min: 0
    },
    end: {
      hour: 1, min: 0
    }
  },

}


export default new Helper();
export {
  meals,
}

