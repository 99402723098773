import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import './scss/style.scss';
import IConnect from "./views/icast/IConnect";
import ClientView from "./views/client/ClientView";
// import { GuardProvider, GuardedRoute } from 'react-router-guards';

const Loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(()=> import('./views/login/Login'));
const Test = React.lazy(()=> import('./views/dashboard/Test'));

const AdminScreen = React.lazy(()=>import('./views/adminscreen/admin_screen'));

const GuestScreen = React.lazy(()=>import('./views/guestscreen/guest_screen'));

const LoginGuest = React.lazy(()=>import('./views/login/LoginGuest'));
const RegisterGuest = React.lazy(()=>import('./views/register/Register'));
const GuestSessions = React.lazy(()=>import('./views/session/guest'));

const AdminChat = React.lazy(()=>import('./views/chatV2/admin/AdminChatComponent'));
const UserChat = React.lazy(()=>import('./views/chatV2/user/UserChatComponent'));

const NotFound = (<h3>Not found</h3>);
// const Exercises = React.lazy(()=>import('./views/exercises/Exercises'));

// const AdminRegister = <div>Admin Register</div>;
// const beforeRoute = (to, from, next) => {
//   if (true) {
//     console.log('guard');
//     next();
//   }
//   next.redirect('/login');
// };

const App = () =>  {

    return (
      <BrowserRouter>
        <React.Suspense fallback={Loading}>
          <Switch>
            {/*<Route path={'/admin-register'} exact={true} name={'Register'} render={props => <AdminRegister {...props}/>}/>*/}
            <Route path={'/admin/chat'} exact={true} name={'AdminChat'} render={props => <AdminChat {...props}/>}/>
            <Route path={'/user/chat'} exact={true} name={'UserChat'} render={props => <UserChat {...props}/>}/>
            <Route path="/login" exact={true} name="Login" render={props => <Login {...props}/>}/>
            <Route path={'/guest-login'} exact={true} name={'GuestLogin'} render={props => <LoginGuest {...props}/>}/>
            <Route path={'/guest-register'} exac={true} name={'GuestRegister'} render={props =><RegisterGuest {...props}/>}/>
            <Route path={'/guest-screen'} exact={true} name={'Guest-Screen'} render={props => <GuestScreen {...props}/>}/>
            <Route path={'/guest-screen'} exact={true} name={'Guest-Screen'} render={props => <GuestScreen {...props}/>}/>
            <Route path={'/guest-session'} exact={true} name={'Guest-Session'} render={props => <GuestSessions {...props}/>}/>
            <Route path="/icast" exact={true} name="ICast" render={props => <IConnect {...props}/>}/>
            <Route path={'/client-view'} exact={true} name={'ClientView'} render={props =>  <ClientView {...props}/> }/>

            {/*<GuardProvider>*/}
            {/*  <GuardedRoute path={'/user/exercises'} guards={[beforeRoute]}  component={props => <Exercises {...props}/>}/>*/}
            {/*</GuardProvider>*/}

            <Route path={'/'} name={'Home'} render={props =>  <TheLayout {...props}/> } />
            <Route path="/test" name="Test" render={props => <Test {...props}/>}/>
            <Route path={'/admin-screen'} name={'Admin Screen'} render={props => <AdminScreen {...props}/>}/>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
}

export default App;

