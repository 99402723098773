import React, {useEffect, useState} from 'react';
import DashboardHeader from "./DashboardHeader";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import mswLogo from 'src/assets/msw/LogIn_Logo.svg';
import {OTSession,OTPublisher, OTStreams, OTSubscriber} from "opentok-react";
import '@opentok/client';
import cf from "../../config";
import {useRef} from "react";
import phone from 'src/assets/msw/ext/phone.svg';
import video from 'src/assets/msw/ext/video.svg';
import videoSlash from 'src/assets/msw/ext/video-slash.svg';
import mic from 'src/assets/msw/ext/microphone.svg';
import micSlash from 'src/assets/msw/ext/microphone-slash.svg';
import stop from 'src/assets/msw/ext/stop.svg';
import record from 'src/assets/msw/ext/164.svg';
import DashNav from "../dashboard/clientDashboard/DashNav";
import DashBody from "../dashboard/clientDashboard/DashBody";
import DashNutritions from "../dashboard/clientDashboard/DashNutritions";
import DashNotes from "../components/DashGraph/DashNotes";
import fn from "../../utils/Helper";
import MonthSummary from "../../utils/MonthSummary";
import {meals} from "../../utils/Helper";
import GlucoseDayGraph from "../dashboard/clientDashboard/GlucoseDayGraph";
import moment from "moment";

const IConnect = ({...props}) => {

  //console.log(props);
  const medical = useSelector(state => state.tab.medical);
  const exercise = useSelector(state => state.tab.exercise);
  const fluids = useSelector(state => state.tab.fluids);

  const data = useSelector(state => state.graphs.lineGraph.data.datasets);
  const [tirDataSet, setTirDataSet] = useState(null);

  const history = useHistory();
  const [iframeWidth, setIframeWidth] = useState(0);
  const [videoChat, setVideoChat] = useState(JSON.parse(localStorage.getItem('videoChat')));
  const [publishVideo, setPublishVideo] = useState(true);
  const [muted, setMuted] = useState(false);
  const [archiveVideo, setArchiveVideo] = useState(false);
  const [connection, setConnection] = useState('Connecting');
  const [error, setError] = useState(null);
  const [stream, setStream] = useState(null);
  const [session, setSession] = useState(null);
  const [subscribers, setSubscribers] = useState([]);

  const [graphs, setGraphs] = useState(true);
  const [modal, setModal] = useState(false);
  const leftSide = useRef();
  const rightSide = useRef();
  const iframe = useRef();
  const sub = useRef();

  const [date, setDate] = useState(new Date());
  const [monthSummary, setMonthSummary] = useState(null);

  const [medicalTables, setMedicalTables] = useState([]);
  const [fluidsTables, setFluidsTables] = useState([]);
  const [exerciseTables, setExerciseTables] = useState([]);

  const [dataSets, setDataSets] = useState([]);
  const [range, setRange] = useState(null);
  const [rangeBottom, setRangeBottom] = useState(null);
  const [glucoseWeek, setGlucoseWeek] = useState(0);
  const [nutritionWeek, setNutritionWeek] = useState(0);
  const [nutrition, setNutrition] = useState(null);

  const [lastProfile, setLastProfile] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [dayOpen, setDayOpen] = useState(null);
  const [dayData, setDayData] = useState(null);

  const [notes, setNotes] = useState(null);

  const onDayOpen = (ev) => {
    const day = ev.target.getAttribute('data-day');
    //console.log(day);
    setDayOpen(day);
    setIsModal(true);
  }

  useEffect(()=>{
    if(monthSummary && dayOpen){
      const day = monthSummary.medical.glucose.weeks[glucoseWeek][dayOpen];
      //console.log(day);
      if(day){
        setDayData({
          id: 'daySummary',
          label: 'Day Summary',
          data: day.map(d => d.value).reverse(),
          lab: day.map(d => {
            return moment(d.created_at).format('HH:mm');
          }).reverse(),
          borderColor: '#ff0',
          backgroundColor: '#ff0',
          fill: 'start',
        });
      }else{
        setDayData(null);
      }
      //setDayData(monthSummary.medical.glucose.weeks[glucoseWeek][dayOpen]);
    }
  },[dayOpen])

  useEffect(()=>{
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken')) ?? null;
    if(bearerToken){
      //console.log(props);
      const userId = props.location.state.id;
      fetch(`${cf.API_BASE}/${cf.usersEndpoint}/${userId}`,{
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application-json',
          Authorization: 'Bearer ' + bearerToken
        }
      }).then(res => res.json())
        .then(res => {
          //console.log(res);
          if(res.user){
            localStorage.setItem('lastProfile', JSON.stringify(res.user));
            setLastProfile(res.user);
          }else{
            //history.push('/client-list');
            history.push({pathname: '/client-list', state: props.location.state});
          }
        }).catch(err => {
        if(err){
          history.push('/login');
        }
        console.log(err);
      });
    }

  },[])

  useEffect(()=>{
    if(monthSummary){
      const nutritionTable = [];
      monthSummary.nutrition.weeks.forEach((nut, week) => {
        if(week === nutritionWeek) {
          //console.log(nut, mS._weekDates[nutritionWeek]);
          monthSummary._weekDates[nutritionWeek].days.forEach(day => {
            //console.log(nut[day]);
            if(!nut[day]) return;
            nut[day].forEach( item => {
              const dayOfWeek = fn.dayOfTheWeek(item.eaten_at);
              const hour = fn.hour(item.eaten_at);
              const min = fn.min(item.eaten_at);
              // const dayOfWeek = fn.dayOfTheWeek(item.created_at);
              // const hour = fn.hour(item.created_at);
              // const min = fn.min(item.created_at);
              //console.log(day);
              nutritionTable.push({
                created_at: item.created_at,
                eaten_at:item.eaten_at,
                title: item.title,
                calories: item.calories,
                fat: item.fat,
                fibre: item.fibre,
                protein: item.protein,
                salt: item.salt,
                satFat: item.saturated_fat,
                sugar: item.sugar,
                type: fn.foodType(item.eaten_at, meals),
                // type: fn.foodType(item.created_at, meals),
                day: fn.getDay(dayOfWeek),
                hour: hour.toString().padStart(2,'0'),
                min: min.toString().padStart(2, '0'),
              });
            })
          })
        }
      });
      setNutrition(nutritionTable);
      //console.log(nutritionTable);
    }
  },[nutritionWeek])

  useEffect(()=>{

    if(monthSummary) {

      const glucose = monthSummary.medical.glucose.weeks;
      //console.log(monthSummary);

      const gW = [];
      //console.log('glucose', glucose, glucoseWeek);
      glucose?.forEach((gluco,no) => {
        if(glucoseWeek === no) {
          //console.log(gluco);
          for(const g in gluco){
            if (gluco[g] !== null) {
              gluco[g].forEach((item, idx) => {
                gW.push({date: g, value: parseFloat(item.value), index: idx})
              });
            } else {
              gW.push({date: g, value: 0, index: null})
            }

          }
        }
      });

      const tirVal = gW.map(g => g.value);

      const tirKey = [];

      gW.forEach((t,idx)=>{
        const date = new Date(t.date);
        const day = fn.getDay(date.getDay());
        //console.log(t.index, (t.index === 0));
        //if(t.index === 0) tirKey.push(day);
        //else tirKey.push('a');
        tirKey.push('');
      });

      //console.log(tirVal, tirKey);

      setTirDataSet({
        id: 'tir',
        label: 'Blood Sugar Levels And Time In Range (%)',
        data: tirVal,
        lab: tirKey,
        borderColor: '#ef5248',
        backgroundColor: 'rgba(239, 82, 72, 0.8)',
        fill: false,
      });

    }


  },[glucoseWeek, monthSummary])

  useEffect(()=>{
    const lastUser = JSON.parse(localStorage.getItem('lastProfile')) || null;
    const bearerToken = JSON.parse(localStorage.getItem('bearerToken')) || null;
    //console.log(lastUser, bearerToken);
    // TODO lastUser.id hard coded for user 1 as API currently returns data only for that user
    // ${cf.API_BASE}/users/${lastUser.id}/summary?startDate=${'2022-01-01'}&endDate=${'2022-02-01'}
    const d = fn.getMonthRanges(date);
    //console.log(lastUser, 'lastuser')
    if(bearerToken){
      fetch(`${cf.API_BASE}/users/${lastUser.id}/summary?startDate=${d.start}&endDate=${d.end}`,{
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          Authorization: 'Bearer '+ bearerToken
        }
      }).then(res => res.json())
        .then(res => {
          //console.log(res);
          if(res.error) return;
          if(res.user_notes){
            if(res.user_notes !== null) setNotes(res.user_notes);
            else setNotes({});
          }
          if(res.user){
            const mS = new MonthSummary(d.start, res);
            setMonthSummary(mS);
            //console.log(mS);
            const weight = mS.medical.weight.weeks;
            const bmi = mS.medical.bodyMassIndex.weeks;
            const bp = mS.medical.bloodPressure.weeks;
            const wd = mS.medical.wornDays.weeks;
            const tir = mS.medical.timeInRange.weeks;
            const tv = [];
            tir.forEach(el => {
              const tmp = []
              for(const o in el){
                tmp.push(el[o]);
              }
              tv.push(tmp);
            })
            const fl = mS.fluids.weeks;
            const nD = 'no data';

            setMedicalTables([
              {
                title: 'Anthropometrics',
                header:  [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
                rows: [
                  ['Weight (kg)', weight[0] ?? nD, weight[1] ?? nD, weight[2] ?? nD,weight[3] ?? nD],
                  ['BMI', bmi[0] ?? nD, bmi[1] ?? nD, bmi[2] ?? nD, bmi[3] ?? nD],
                  ['Blood Pressure', `${bp[0]?.systolic ?? ''}${bp[0]?.systolic ? '/' : nD}${bp[0]?.diastolic ?? ''}`, `${bp[1]?.systolic ?? ''}${bp[1]?.systolic ? '/' : nD}${bp[1]?.diastolic ?? ''}`, `${bp[2]?.systolic ?? ''}${bp[2]?.systolic ? '/' : nD}${bp[2]?.diastolic ?? ''}`, `${bp[3]?.systolic ?? ''}${bp[3]?.systolic ? '/' : nD}${bp[3]?.diastolic ?? ''}`],
                ],
              },
              {
                title: 'Number of days worn',
                header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
                rows: [
                  ['Days', wd[0],wd[1],wd[2],wd[3]],
                ]
              },
              {
                title: 'Time in Range',
                header: [{title: ''}, {title: 'DAY 1'}, {title: 'DAY 2'}, {title: 'DAY 3'},
                  {title: 'DAY 4'}, {title: 'DAY 5'}, {title: 'DAY 6'}, {title: 'DAY 7'}],
                rows: [
                  ['Week 1 (%)', tv[0][0] ?? nD, tv[0][1] ?? nD, tv[0][2] ?? nD, tv[0][3] ?? nD, tv[0][4] ?? nD, tv[0][5] ?? nD, tv[0][6] ?? nD ],
                  ['Week 2 (%)', tv[1][0] ?? nD, tv[1][1] ?? nD, tv[1][2] ?? nD, tv[1][3] ?? nD, tv[1][4] ?? nD, tv[1][5] ?? nD, tv[1][6] ?? nD],
                  ['Week 3 (%)', tv[2][0] ?? nD, tv[2][1] ?? nD, tv[2][2] ?? nD, tv[2][3] ?? nD, tv[2][4] ?? nD, tv[2][5] ?? nD, tv[2][6] ?? nD],
                  ['Week 4 (%)', tv[3][0] ?? nD, tv[3][1] ?? nD, tv[3][2] ?? nD, tv[3][3] ?? nD, tv[3][4] ?? nD, tv[3][5] ?? nD, tv[3][6] ?? nD],
                ]
              },
            ]);

            setFluidsTables([
              {
                title: 'Fluids',
                header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
                rows: [
                  ['Water (litres)', fl[0].water,fl[1].water,fl[2].water,fl[3].water],
                  ['Alcohol (units)', fl[0].alcohol,fl[1].alcohol,fl[2].alcohol,fl[3].alcohol],
                  ['Caffeine (units)', fl[0].caffeine,fl[1].caffeine,fl[2].caffeine,fl[3].caffeine],

                ]
              }
            ]);

            const weightVal = weight.map(w => w);
            const weightKey = weight.map((w,idx) => (idx+1))
            const bmiVal = bmi;
            const bmiKey = bmi.map((b,idx) => (idx+1));
            const bpValSys = bp.map(p => p?.systolic);
            const bpKeySys = bp.map((p,idx) => (idx+1));

            const daysVal = wd.map(p => ((p !== 0) ? p : null));
            const daysKey = wd.map((p,idx) => (idx+1));

            //console.log(res.user_glucose);
            const glucose = mS.medical.glucose.weeks;
            //console.log(glucose);
            const gW = [];
            glucose?.forEach((gluco,no) => {
              if(glucoseWeek === no) {
                for(const g in gluco){
                  //console.log(g, gluco[g]);
                  gluco[g]?.forEach((item, idx) => {
                    gW.push({date: g, value: item.value, index: idx})
                  });
                }

              }
            });
            //console.log(gW);
            const gl = res.user_glucose;
            const tirVal = gW.map(g => parseFloat(g.value));
            const tirKey = [];

            gW?.forEach((t,idx)=>{
              const date = new Date(t.date);
              const day = fn.getDay(date.getDay());
              //if(t.index === 0) tirKey.push(day);
              //else tirKey.push('a');
              tirKey.push('');
            })

            const maxVal = fn.getMax(gl);
            //console.log(maxVal);
            const tirTopVal = gl.map((g, idx) => 10);
            const tirTopKey = gl.map(g => '');
            const tirBottomVal = gl.map((g, idx) => 3.9);
            const tirBottomKey = gl.map(g => '');

            // Nutrition Table
            const nutritionTable = [];
            mS.nutrition.weeks.forEach((nut, week) => {
              if(week === nutritionWeek) {
                //console.log(nut, mS._weekDates[nutritionWeek]);
                mS._weekDates[nutritionWeek].days.forEach(day => {
                  //console.log(nut[day]);
                  nut[day]?.forEach( item => {
                    const hour = fn.hour(item.eaten_at);
                    const min = fn.min(item.eaten_at);
                    const dayOfWeek = fn.dayOfTheWeek(item.eaten_at);
                    // const hour = fn.hour(item.created_at);
                    // const min = fn.min(item.created_at);
                    // const dayOfWeek = fn.dayOfTheWeek(item.created_at);
                    //console.log(dayOfWeek)
                    nutritionTable.push({
                      created_at: item.created_at,
                      eaten_at: item.eaten_at,
                      title: item.title,
                      calories: item.calories,
                      fat: item.fat,
                      fibre: item.fibre,
                      protein: item.protein,
                      salt: item.salt,
                      satFat: item.saturated_fat,
                      sugar: item.sugar,
                      type: fn.foodType(item.eaten_at, meals),
                      // type: fn.foodType(item.created_at, meals),
                      day: fn.getDay(dayOfWeek),
                      hour: hour.toString().padStart(2,'0'),
                      min: min.toString().padStart(2, '0'),
                    });
                  })
                })
              }
            });

            setNutrition(nutritionTable);

            setRange({
              id: 'range',
              label: 'range',
              data: tirTopVal,
              //lab: tirTopKey,
              borderColor: 'rgba(255, 2, 5, 0)',
              backgroundColor: 'rgba(255, 2, 5, 0.5)',
              fill: 'end',
            });
            setRangeBottom({
              id: 'rangeBottom',
              label: 'rangeBottom',
              data: tirBottomVal,
              //lab: tirBottomKey,
              borderColor: 'rgba(255, 2, 5, 0)',
              backgroundColor: 'rgba(255, 2, 5, 0.5)',
              fill: 'start',
            });

            setDataSets([
              {
                id: 'days',
                label: 'Number of Days Worn',
                data: daysVal,
                lab: daysKey,
                borderColor: '#ec9e30',
                backgroundColor: 'rgba(255, 99, 132, 1)',
              },
              {
                id: 'tir',
                label: 'Blood Sugar Levels And Time In Range (%)',
                data: tirVal,
                lab: tirKey,
                borderColor: '#ef5248',
                backgroundColor: 'rgba(53, 162, 235, 1)',
                fill: true,
              },
              {
                id: 'saturated',
                label: 'Saturated',
                data: weightVal,
                lab: weightKey,
                borderColor: '#84987e',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'salt',
                label: 'Salt',
                data: weightVal,
                lab: weightKey,
                borderColor: '#312182',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'weight',
                label: 'Weight',
                data: weightVal,
                lab: weightKey,
                borderColor: '#000',
                backgroundColor: 'rgba(255, 99, 132, 1)',
              },
              {
                id: 'waist',
                label: 'Waist',
                data: weightVal,
                lab: weightKey,
                borderColor: '#000',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'bmi',
                label: 'BMI',
                data: bmiVal,
                lab: bmiKey,
                borderColor: '#f00',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'bloodPressure',
                label: 'Blood Pressure',
                data: bpValSys,
                lab: bpKeySys,
                borderColor: '#0f0',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'freq',
                label: 'Frequency',
                data: weightVal,
                lab: weightKey,
                borderColor: '#408228',
                backgroundColor: 'rgba(255, 99, 132, 1)',
              },
              {
                id: 'intense',
                label: 'Intensity and type',
                data: weightVal,
                borderColor: '#408228',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'time',
                label: 'Time',
                data: weightVal,
                lab: weightKey,
                borderColor: '#408228',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'calories',
                label: 'Calories Burnt',
                data: weightVal,
                lab: weightKey,
                borderColor: '#408228',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
              {
                id: 'fluids',
                label: 'Fluids',
                data: weightVal,
                lab: weightKey,
                borderColor: '#003228',
                backgroundColor: 'rgba(53, 162, 235, 1)',
              },
            ]);


          }
        }).catch(err => {
        console.log(err);
      })

    }

  }, [date]);

  useEffect(()=>{
    const widthL = leftSide.current.clientWidth;
    setIframeWidth(widthL);
    iframe.current.setAttribute('width', `${widthL}`);
    iframe.current.style.height = '100vh';
    //console.log(history.go(0));
  },[])

  const moveToOriginalSize = () => {
    iframe.current.setAttribute('width', `${iframeWidth}`);
    leftSide.current.style.maxWidth = `${iframeWidth}px`;
    rightSide.current.style.maxWidth = `${window.innerWidth - iframeWidth}px`;
  }

  const onResizeClick = (ev) => {
    if(ev.pageX === 0) return;
    let leftToRightRatio = ev.pageX/window.innerWidth;
    if(leftToRightRatio <= 0) leftToRightRatio = 0;
    if(leftToRightRatio >= 0.75) leftToRightRatio = 0.75;
    const leftSideWidth = leftToRightRatio * window.innerWidth;
    let rightSideWidth = window.innerWidth - leftSideWidth;
    if(rightSideWidth <= 500) {rightSideWidth = 500};
    leftSide.current.style.maxWidth = `${window.innerWidth - rightSideWidth}px`;
    rightSide.current.style.maxWidth = `${rightSideWidth}px`;
    iframe.current.setAttribute('width', `${window.innerWidth - rightSideWidth}`);
    //console.log(sub.current.childNodes[0].childNodes);
    //sub.current.node.style.height = '100px';

    const children = sub.current.childNodes[0].childNodes.length;
    const height = (iframe.current.clientHeight - 62)/children;
    sub.current.childNodes[0].childNodes.forEach(node => {
      node.childNodes[0].style.height = `${height}px`;
    });
  }

  const sessionEventHandlers = {
    sessionConnected: (ev) => {
      //console.log(ev);
      setSession(ev);
      setConnection( 'Connected' );
    },
    sessionDisconnected: () => {
      setConnection( 'Disconnected' );
    },
    sessionReconnected: () => {
      setConnection(  'Reconnected' );
    },
    sessionReconnecting: () => {
      setConnection(  'Reconnecting' );
    },
  };

  const publisherEventHandlers = {
    accessDenied: () => {
      console.log('User denied access to media source');
    },
    streamCreated: (ev) => {
      setStream(ev);
      ev.stream.setVideoDimensions(320, 240);
      console.log('Publisher stream created');
    },
    streamDestroyed: ({ reason }) => {
      console.log(`Publisher stream destroyed because: ${reason}`);
    },
    audioLevelUpdated: (ev) => {
      //console.log(ev);
    },
    setAudioVolume(value) {

    }

  };

  const subscriberEventHandlers = {
    videoElementCreated: (ev) => {
      //console.log(ev);
      const sub = subscribers;
      sub.push(ev.target);
      setSubscribers(sub);
    },
    videoEnabled: () => {
      console.log('Subscriber video enabled');
    },
    videoDisabled: () => {
      console.log('Subscriber video disabled');
    },
  };

  const onSessionError = (err) => {
    console.log(err);
    if(err){
      //history.push('/client-list');
      history.push('/')
    }
    setError(err);
  };

  const onPublish = () => {
    console.log('Publish Success');
  };

  const onPublishError = error => {
    setError( error );
  };

  const onSubscribe = () => {
    const children = sub.current.childNodes[0].childNodes.length;
    const height = (iframe.current.clientHeight - 62)/children;
    sub.current.childNodes[0].childNodes.forEach(node => {
      node.childNodes[0].style.height = `${height}px`;
    });
    toggleArchiving();
    console.log('Subscribe Success');
  };

  const toggleArchiving = () => {
    if(!archiveVideo){
      fetch(`${cf.API_BASE}/${cf.videoChatEndpoint}/${videoChat.videoChat.id}/archives`, {
        method: 'POST',
        headers: {
          // Admin token
          Authorization: 'Bearer ' + videoChat.bearerToken
        }
      }).then(res => res.json())
        .then(res => {
          console.log(res, 'Archiving started');
          setArchiveVideo(true);
        })
    }else {
      // STOP Archiving
    }

  }
  const endCall = () => {
    //console.log(session)
    if(session) session.target.disconnect();
  }

  const togglePublisherMic = () => {
    if(stream){
      //console.log(stream);
      stream.target.publishAudio(muted);
      setMuted(()=>!muted);
      //session.target.forceMuteStream(stream.stream);
      //console.log(stream.stream);
    }

  }

  const onSubscribeError = error => {
    setError( error );
  };

  const toggleVideo = () => {
    setPublishVideo(()=>!publishVideo);
    //console.log(publishVideo);
  };

  const onArchiveStarted = (ev) => {
    //console.log(ev);
  }

  return (
    <>
      <div className="icast">
        <div ref={leftSide} className="icast-left">

          <div className={'icast-video'} ref={iframe}>

            {/*<div id="sessionStatus">Session Status: {connection}</div>
                {error ? (
                  <div className="error">
                    <strong>Error: {error.message}</strong>
                  </div>
                ) : null}
                */}

                <OTSession
                  apiKey={cf.API_KEY}
                  sessionId={videoChat?.videoChat?.vonage_session ?? ' '}
                  token={videoChat?.videoChat?.token ?? ' '}
                  onError={onSessionError}
                  eventHandlers={sessionEventHandlers}
                  onArchiveStarted={onArchiveStarted}
                >

                  <div className={'icast-video-publisher-wrapper'}>

                    <div>
                      <OTPublisher
                        className={'icast-video-publisher'}
                        properties={{
                          publishVideo,
                          width: 240, height: '100%',
                          insertMode: 'append',
                          showControls: false,
                          fitMode: 'contain',
                        }}
                        onPublish={onPublish}
                        onError={onPublishError}
                        eventHandlers={publisherEventHandlers}
                      />
                      <div className={'icast-control'}>
                        <button
                          onClick={endCall}
                          className={'endCall'}
                        >
                          <img src={phone} alt={''}/>
                        </button>
                        <button id="videoButton" onClick={toggleVideo}>
                          {(publishVideo) ?
                            (<img src={video} alt={''}/> ) :
                            (<img src={videoSlash} alt={''}/> )}
                        </button>
                        <button id="soundButton" onClick={togglePublisherMic}>
                          {(muted) ?
                            (<img src={micSlash} alt={''}/> ) :
                            (<img src={mic} alt={''}/> )}
                        </button>
                        {/*<button id={'recordButton'} onClick={toggleArchiving}>
                          {(!archiveVideo) ?
                            (<img src={record} alt={''}/>):
                            (<img src={stop} alt={''}/>)
                          }
                        </button>*/}
                      </div>
                    </div>


                  </div>

                  <div ref={sub} className={'icast-video-subscriber-wrapper'}>

                    <OTStreams>
                      <OTSubscriber
                        className={'icast-video-subscriber'}
                        properties={{
                          insertMode: 'append',
                          width: '100%', height: '100%',
                          showControls: true,
                          fitMode: 'contain',
                        }}
                        onSubscribe={onSubscribe}
                        onError={onSubscribeError}
                        eventHandlers={subscriberEventHandlers}
                      />
                    </OTStreams>

                  </div>


                </OTSession>

          </div>

          <div
            className={'icast-left-resizeBtn-wrapper'}
            onDrag={onResizeClick}
          >
            <div className={'icast-left-resizeBtn-wrapper-logo'}>
              <img src={mswLogo} alt={''}/>
            </div>
            <div
              onClick={moveToOriginalSize}
              draggable={true}
              className={'icast-left-resizeBtn'}
            >
              <div draggable={true}>&#8227;</div>
            </div>
          </div>


        </div>
        <div ref={rightSide} className="icast-right">
          <div style={{position: 'absolute', left: '200px', top: '20px'}}>
            <button
              onClick={()=>{history.push('/client-list/profile-view')}}
              style={{border: '1px solid #312182', backgroundColor: '#312182', color: '#fff', borderRadius: '5px', cursor:'pointer', zIndex: 1}}>GO BACK</button>
          </div>
          {lastProfile ?
            (
              <DashboardHeader
                client={lastProfile}
                show={modal}
                onClose={setModal}
              />
            )
            : ''}



          <DashNav
            localStyle={{position: 'sticky'}}
            date={date}
            setDate={setDate}
            nutritionWeek={nutritionWeek}
          />

          <div className="icast-right-dashboard-wrapper">
            <div className={'ClientDashboard-body-content' + (!modal ? '-inSplit' : '')}>

              {/*<DashboardBody
                  graphsPage={graphsPage}
                />*/}
              <DashBody
                title={'MEDICAL'}
                tables={medicalTables}
                isGraph={true}
                data={dataSets}
                graphs={['weight','bmi','bloodPressure']}
                isSubtitle={true}
                xLabel={['WEEKS', 'DAYS', 'NONE']}
                yLabel={['Kg', 'index', 'mmHg']}
                isMultiple={true}
                range={range}
                rangeBottom={rangeBottom}
                tirDataSet={tirDataSet}
                setGlucoseWeek={setGlucoseWeek}
                glucoseWeek={glucoseWeek}
                monthSummary={monthSummary}
                onDayOpen={onDayOpen}
              />

              <DashBody
                title={'EXERCISE'}
                tables={exercise}
                isGraph={false}
                data={data}
                isSubtitle={false}
                xLabel={['X']}
                yLabel={['Y']}
              />

              <DashBody
                title={'FLUIDS'}
                tables={fluidsTables}
                isGraph={true}
                data={data}
                graphs={['fluids']}
                isSubtitle={false}
                xLabel={['DAYS']}
                yLabel={['LITRES']}
              />

              <DashNutritions
                nutrition={nutrition}
                date={date}
                setDate={setDate}
                setNutritionWeek={setNutritionWeek}
                nutritionWeek={nutritionWeek}
                monthSummary={monthSummary}
              />

              {notes ?
                (
                  <DashNotes
                    notes={notes}
                  />
                )
                : ('')}


            </div>
          </div>


        </div>
      </div>

      {dayData
        ?
        (
          <GlucoseDayGraph
            isModal={isModal}
            setIsModal={setIsModal}
            dayData={dayData}
          />
        )
        : ''}

    </>
  );
}

export default IConnect;
