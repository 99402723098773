// Make a copy of this file and save it as config.js (in the src directory).

// Set this to the base URL of your sample server, such as 'https://your-app-name.herokuapp.com'.
// Do not include the trailing slash. See the INFO.md for more information:
const process = require('process');
const SAMPLE_SERVER_BASE_URL = 'http://YOUR-SERVER-URL';

// OR, if you have not set up a web server that runs the learning-opentok-php code,
// set these values to OpenTok API key, a valid session ID, and a token for the session.
// For test purposes, you can obtain these from https://tokbox.com/account.

const API_KEY = '47444661';
 const API_BASE = 'https://api.msw.xrf.digital';
//  const API_BASE = 'http://127.0.0.1:8000';
// const API_BASE = 'http://192.168.87.239:8000';
const videoChatEndpoint = 'admin/video-chats';
const usersEndpoint = 'users';
const adminEndpoint = 'admin';
const SESSION_ID = 'sessionID';
const TOKEN = 'a6VWGTgewJ275xWrk9HmNMUtCLvfydIdt2FaRNzu';
const {env} = process;
// console.log('ENV', env.APP_MODE, process);

// //const LINK_BASE = 'https://api.msw.xrf.digital/openTok.html?id=';

// const NEW_LINK_BASE = 'http://localhost:3000/client-view?id={video_chat_id}';
// const LINK_BASE = 'http://localhost:3000/client-view?id=';

 const NEW_LINK_BASE = 'https://mswiconnect.staging.xrf.digital/client-view?id={video_chat_id}';
 const LINK_BASE = 'https://mswiconnect.staging.xrf.digital/client-view?id=';

const getAllEventsEndpoint = 'admin/event';
//const currentVideoChatEndpoint = 'admin/events-video-chats';
const videoChatTokenEndpoint = 'admin/events-video-chats/token';
const registerUserEventEndpoint = 'user/register';

const user = {
  id: 3,
  email: "greg@xreflow.co.uk",
  password: "LadBttlxSZ1L",
  name: "android_test_phone"
}

const getChatToken = async (token) => {
  return await fetch(`${API_BASE}/admin/chat/tokens`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: 'Bearer ' + token
    }
  });
}

const getToken = (user) => {
  for(const [name, value] of Object.entries(user)){
    if(user[name] === '') return;
    //console.log(user[name]);
  }
  try {
    return fetch(`${API_BASE}/admin/users/tokens`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: user.email,
        password: user.password,
        name: navigator.userAgent
      })
    });
  }catch(err){
    console.log(err, 'config getToken');
  }

}

// ------- MSW v2 API  part for local development

const registerUserEvent = async (data) => {
  return await fetch(`${API_BASE}/${registerUserEventEndpoint}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      "username": data.username,
      "first_name": data.first_name,
      "surname": data.surname,
      "email": data.email,
    })
  });
}

const getVideoChatIdGuest = async (data) => {
  console.log('getVideoChatID before FETCH', data)
  return await fetch(`${API_BASE}/${videoChatTokenEndpoint}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + data.token
    },
    body: JSON.stringify({
      "video_chat" : {
        "video_chat_id": "3ea41ae6-1083-43fa-88d4-92c25be75bba"
      }
    })
  })
}

const getAllEvents = (data) => {
  //console.log('get all events', data);
    return fetch(`${API_BASE}/${getAllEventsEndpoint}`,{
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: 'Bearer ' + data.token
      },
    })
}

const startEvent = (data) => {
  //console.log('get all events', data);
  return fetch(`${API_BASE}/${getAllEventsEndpoint}`,{
    method: 'PATCH',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + data.token
    },
    body: JSON.stringify({
      "video_event": {
        "id": data.id
      }
    })
  })
}

const fetchSessionGuest = async (id) => {
  return await fetch(`${API_BASE}/${videoChatEndpoint}/${id}`,{
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + TOKEN,
    }
  })
}

const req_get = async (url, token) => {
  return await fetch(`${API_BASE}${url}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
}

const isGuestTokenValid = (token, callback) => {
  if(token && callback) {
    // todo  check token validity
    fetch(`${API_BASE}/user/token`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    }).then(res => res.json())
      .then(res => {
        //console.log(res);
        callback({guest_token: res.token, valid:res.valid});
      }).catch(err => {
      console.log(err);
    });
  }else{
    return false;
  }
}

const getUserGuestEvents = (token, callback, redirect) => {

  if(token && callback){
    try{
      fetch(`${API_BASE}/user/session`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + token
        }
      }).then(res => res.json())
        .then(res => {
          // console.log(res);
          if(res.status === 200) callback(res.event_sessions);
          //console.log(res.video_events);
        }).catch(err => {
        console.log(err);
        if(err) redirect({where: '/guest-login', state: true});
      })
    }catch(err){
      console.log(err);
      return err;
    }
  }else{
    try{
      callback(false);
    }catch(err){
      console.log(err);
    }
    return false;
  }
}

const getUserBookings = (token, callback) => {
  if(token && callback){
    fetch(`${API_BASE}/user/sessions`,{
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      }
    }).then(res => res.json())
      .then(res => {
        if(res.status === 200) callback(res.events);
        //console.log(res);
      }).catch(err => {
        console.log(err);
    })
  }else{
    try{
      callback(false);
    }catch(err){
      console.log(err);
    }
    return false;
  }
}

// -------------------------------




const getVideoChatId = async (data) => {
  //console.log('getVideoChatID before FETCH', data)
  return await fetch(`${API_BASE}/${videoChatEndpoint}`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + data.token
    },
    body: JSON.stringify({
      "video_chat" : {
        "participant_id": data.participantId,
        "send_email": data.sendEmail,
        "url_pattern": data.urlPattern
      }
    })
  })
}

const fetchSession = async (id) => {
  return await fetch(`${API_BASE}/${videoChatEndpoint}/${id}`,{
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + TOKEN,
    }
  })
}

const fetchVideoChats = async (token) => {
  return await fetch(`${API_BASE}/${videoChatEndpoint}`,{
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  });
}

const getVideoChatArchive = async (token, id) => {
  return await fetch(`${API_BASE}/${videoChatEndpoint}/${id}/archives`,{
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: 'Bearer ' + token,
    }
  })
}

const config = {
  user,
  API_BASE,
  videoChatEndpoint,
  usersEndpoint,
  SAMPLE_SERVER_BASE_URL,
  API_KEY,
  SESSION_ID,
  NEW_LINK_BASE,
  LINK_BASE,
  TOKEN,
  getToken,
  getVideoChatId,
  fetchSession,
  fetchVideoChats,
  getVideoChatArchive,
  getChatToken,
  adminEndpoint,
  getVideoChatIdGuest,
  fetchSessionGuest,

  getAllEvents,
  startEvent,
  registerUserEvent,
  isGuestTokenValid,
  getUserGuestEvents,
  getUserBookings,

  req_get,

}

export default config;

