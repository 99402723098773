import React, {useEffect} from 'react';
import {CInput, CInputGroup, CRow, CCol} from "@coreui/react";
import './dash-nav-nutrition.scss';
import {useState} from 'react';
import CIcon from "@coreui/icons-react";
import {cilChart, cilGraph} from "@coreui/icons";
import NutritionDay from "./NutritionDay";
import {useSelector} from "react-redux";
import fn from '../../../utils/Helper';
import moment from "moment";

const DashNutritionNav = ({...props}) => {
  const daysOfTheWeek = useSelector(state => state.daysOfTheWeek);
  const [isWeekActive, setIsWeekActive] = useState({week1: true, week2: false, week3: false, week4: false, week5: false});
  // isActive initial state from Macros, Food, Week KCals
  const [isActive, setIsActive] = useState([false, true, false]);
  const [isTable, setIsTable] = useState(true);
  const [isExpanded, setIsExpanded] = useState([false, false, false, false, false, false, false]);

  const [weeklyData, setWeeklyData] = useState(null);

  const onSecondaryBtnClick = (ev) => {
    if(ev.target.id === 'macros') setIsActive([true, false, false]);
    else if(ev.target.id === 'food') setIsActive([false,true,false]);
    else if(ev.target.id === 'cals') setIsActive([false, false, true]);
  }

  const onWeekChange = (ev) => {
    const weeks = [];
    for(const week in isWeekActive){
      weeks.push(isWeekActive[week]);
    }
    weeks.forEach((week, idx)=>{
      if(ev.target.id === `week${idx + 1}`) {
        // console.log('idx-35', idx);
        props.setNutritionWeek(idx);
        weeks[idx] = true;
      }
      else weeks[idx] = false;
    });
    const obj = {week1: weeks[0], week2: weeks[1], week3: weeks[2], week4: weeks[3], week5: weeks[4]};
    // console.log('obj-42', obj);
    setIsWeekActive(obj);
  }

  const onDateSelectionClick = (ev) => {
    //console.log(ev.target.value);
    const newDate = new Date(ev.target.value);
    props.setDate(newDate);
  }

  useEffect(()=>{
    if(props.allWeeksSummary){
      const dayData = [];
      // console.log('props.allWeeksSummary', props.allWeeksSummary);
      for(const [key, value] of Object.entries(props.allWeeksSummary[props.nutritionWeek])){
        //dayData.push(value);
        // console.log('key', key, 'value', value, 'props.allWeeksSummary', props.allWeeksSummary, 'props.nutritionWeek', props.nutritionWeek);
        const dayOfTheWeek = fn.getDayOfTheWeek(key);
        // console.log(fn.transformDaySunSatToMonSun(dayOfTheWeek));
        dayData[fn.transformDaySunSatToMonSun(dayOfTheWeek)] = value;
        //console.log(key, value);
      }
      setWeeklyData(dayData)
      //console.log(props.allWeeksSummary[props.nutritionWeek])
    }

  },[props.allWeeksSummary])

  return (
    <>
      <div className="DashNavNutrition-wrapper">

        {/*<div className="DashNavNutrition">
          <div className="DashNavNutrition-nav">
            <CInputGroup className="DNav-nav-group">
              <div>
                Date Range
              </div>
              <CInput
                className="DashNavNutrition-nav-input-date"
                type="date"
              />
              <CInput
                className="DashNavNutrition-nav-input-date"
                type="date"/>
            </CInputGroup>
          </div>
        </div>*/}

        <div className="DashNavNutrition-Pagination-wrapper">
          <div
            onClick={ ()=>{ props.setDate((dt) => {
              const lastMonthLength = (new Date(dt.getFullYear(), dt.getMonth(),0)).getDate();
              const newDate = dt.getTime() - (1000 * 86400 * lastMonthLength);
              return new Date(newDate);
            }) } }
            className="DashNavNutrition-Pagination-prev">Previous</div>
          <div className="DashNavNutrition-Pagination-range">{fn.getMonthRanges5(props.date, props.nutritionWeek).startWeek} - {fn.getMonthRanges5(props.date, props.nutritionWeek).endWeek}</div>
          <div
            onClick={()=>{ props.setDate((dt) => {
              const lastMonthLength = (new Date(dt.getFullYear(), dt.getMonth(),0)).getDate();
              const newDate = dt.getTime() + (1000 * 86400 * lastMonthLength);
              return new Date(newDate);
            })}}
            className="DashNavNutrition-Pagination-next">Next</div>
        </div>
        <div className={'DashNavNutrition-buttons-wrapper'}>
          <button
            onClick={onWeekChange}
            id={'week1'}
            className={'DashNavNutrition-buttons ' + (isWeekActive.week1 ? 'week-active' : 'week')}>Week 1</button>
          <button
            onClick={onWeekChange}
            id={'week2'}
            className={'DashNavNutrition-buttons '+ (isWeekActive.week2 ? 'week-active' : 'week')}>Week 2</button>
          <button
            onClick={onWeekChange}
            id={'week3'}
            className={'DashNavNutrition-buttons '+ (isWeekActive.week3 ? 'week-active' : 'week')}>Week 3</button>
          <button
            onClick={onWeekChange}
            id={'week4'}
            className={'DashNavNutrition-buttons '+ (isWeekActive.week4 ? 'week-active' : 'week')}>Week 4</button>

          {fn.getMonthRanges5(props.date, props.nutritionWeek).lastDay > 28 ? (
            <button
              onClick={onWeekChange}
              id={'week5'}
              className={'DashNavNutrition-buttons '+ (isWeekActive.week5 ? 'week-active' : 'week')}>Week 5</button>
          ) : null}

        </div>

        <div>
          {/*<button
            onClick={onSecondaryBtnClick}
            id={'macros'}
            className={'DashNavNutrition-buttons '+(isActive[0] ? 'active-macros' : 'inactive-macros')}>Macros</button>*/}
          <button
            onClick={onSecondaryBtnClick}
            id={'food'}
            className={'DashNavNutrition-buttons '+(isActive[1] ? 'active-food' : 'inactive-food')}>Food</button>
          <button
            onClick={onSecondaryBtnClick}
            id={'cals'}
            className={'DashNavNutrition-buttons '+(isActive[2] ? 'active-cals' : 'inactive-cals')}>Week KCals</button>
        </div>

        <div>
          {(props.isExpanded && props.isGraphNavOn && false) ?
            (<CRow className="DBody-row rowToCenter">
              <CIcon
                className={'dashNav-nav-icon ' + (!isTable ? 'icon-active' : '') }
                size="lg" content={cilGraph}
              /><CIcon
              className={'dashNav-nav-icon ' + (isTable ? 'icon-active' : '') }
              size="lg" content={cilChart}
            />
            </CRow>) :
            (' ')}
        </div>

        <div className={'DashNavNutrition-content-wrapper'}>
          {daysOfTheWeek.map((day,idx) => (
              <CRow
                key={'nutritionDay-'+fn.uuId()}
                className={'DashNavNutrition-content'}>
                {/*{weeklyData ? console.log('weeklyData[idx]', weeklyData[idx]) : null}*/}
                <CCol className={''} lg={'12'} md={'12'} sm={'12'}>
                  {weeklyData ?
                    (
                      weeklyData[idx] ?
                      <NutritionDay
                        idx={idx}
                        // instead of Monday - Sunday, show day for certain date (weeklyData[idx])
                        // day={day}
                        day={moment(weeklyData[idx].eaten_at).format('dddd')}
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        nutrition={props.nutrition}
                        allWeeksSummary={props.allWeeksSummary}
                        date={props.date}
                        nutritionWeek={props.nutritionWeek}
                        dayData={weeklyData[idx]}
                      /> : ''
                    )
                    : ''}

                </CCol>
              </CRow>
          ))}

        </div>


      </div>
    </>
  );
};


export default DashNutritionNav;
