import moment from "moment";

export default class MonthSummary {


    static DEFAULT_DATE_KEY = 'created_at';
    static NUTRITION_DATE_KEY = 'eaten_at';

    constructor(startDateString, rawMonthRecords) {
        this._weekDates = this._generateWeekDates(startDateString);
        this.medical = {};

        this.medical.weight = this._generateWeightSummary(
            rawMonthRecords.user_weight
        );

        this.medical.bodyMassIndex = this._generateBodyMassIndexSummary(
            rawMonthRecords.user.height_cm,
            this.medical.weight
        );

        this.medical.bloodPressure = this._generateBloodPressureSummary(
            rawMonthRecords.user_blood_pressure
        );

        this.medical.glucose = this._generateGlucoseSummary(
            rawMonthRecords.user_glucose
        );

        this.medical.wornDays = this._generateWornDaysSummary(
            this.medical.glucose
        );

        this.medical.timeInRange = this._generateTimeInRangeSummary(
            this.medical.glucose
        );

        this.exercise = this._generateExerciseSummary(
            rawMonthRecords.user_activities
        );

        this.fluids = this._generateFluidSummary(
            rawMonthRecords.user_fluids
        );

        this.nutrition = this._generateNutritionSummary(
            rawMonthRecords.user_nutrition
        );
    }

    _generateWeekDates(startDateString) {
        const startDate = new Date(startDateString);
        const dayInMilliseconds = 24 * 3600 * 1000;
        const weekInMilliseconds = 7 * dayInMilliseconds;

      // console.log('startDateString', moment(startDateString).format('YYYY-MM-DD HH:mm:ss'));

        const weekDates = [{
            start: startDate.getTime(),
            end: startDate.getTime() + weekInMilliseconds - 1000,
            days: [startDate.getTime()]
        }];


        for (let i = 1; i < 7; i += 1) {
            // console.log('weekDates[0].days[i - 1]',weekDates[0].days[i - 1], 'i', i);
            weekDates[0].days[i] = i < 7
                ? weekDates[0].days[i - 1] + dayInMilliseconds
                : weekDates[0].days[i - 1] + dayInMilliseconds + 1000;
        }

        for (let i = 1; i < 5; i += 1) {
            weekDates[i] = {
                start: weekDates[i - 1].end + 1000,
                end: weekDates[i - 1].end + weekInMilliseconds,
                days: [weekDates[i - 1].end + 1000]
            };

            for (let j = 1; j < 7; j += 1) {
                // console.log('weekDates[i].days[j - 1] + dayInMilliseconds', weekDates[i].days[j - 1] + dayInMilliseconds);
                weekDates[i].days[j] = weekDates[i].days[j - 1] + dayInMilliseconds;
            }
        }


        return weekDates.map((rule) => ({
            start: new Date(rule.start).toISOString().slice(0, 19),
            end: new Date(rule.end).toISOString().slice(0, 19),
            days: rule.days.map((day) => new Date(day).toISOString().slice(0, 10))
        }));
    }

    _groupByWeek(records, useKey = MonthSummary.DEFAULT_DATE_KEY) {
        const temp = [[], [], [], [], []];

        for (const record of records) {
            for (let i = 0; i < 5; i += 1) {
                if (
                    record[useKey] >= this._weekDates[i].start
                    && record[useKey] <= this._weekDates[i].end
                ) {
                    temp[i].push(record);
                    break;
                }
            }
        }

        return temp;
    }

    _groupByDays(records, useKey = MonthSummary.DEFAULT_DATE_KEY) {
        const temp = [];

        for (let i = 0; i < 5; i += 1) {
          const item = this._weekDates[i].days.reduce((result, current) => ({ ...result, [current]: null }), {});
          //console.log('item',item);
          temp.push(item);
        }


        for (const record of records) {
          // console.log('record',record, this._weekDates);
            for (let i = 0; i < 5; i += 1) {
                if (record[useKey] >= this._weekDates[i].start && record[useKey] <= this._weekDates[i].end) {

                    let date = record[useKey].slice(0, 10);

                    if (temp[i][date] === null) {
                        temp[i][date] = [];
                    }
                    // console.log(record);
                    temp[i][date].push(record);
                    break;
                }
            }
        }
        // console.log(temp);
        return temp;
    }

    _generateWeightSummary(weight) {
        return {
            weeks: this._groupByWeek(weight)
                .map((week) => {
                    if (week.length > 0) {
                        const average = week.reduce(
                            (sum, record) => sum + parseFloat(record.value),
                            0
                        ) / week.length;

                        return Math.round(average * 100) / 100;
                    }

                    return null;
                })
        };
    }

    _generateBodyMassIndexSummary(height, weightSummary) {
        return {
            weeks: weightSummary.weeks.map((averageWeight) => {
                if (averageWeight > 0) {
                    const bodyMassIndex = averageWeight / height / height * 10000;

                    return Math.round(bodyMassIndex * 100) / 100;
                }

                return null;
            })
        };
    }

    _generateBloodPressureSummary(bloodPressure) {
        return {
            weeks: this._groupByWeek(bloodPressure)
                .map((bloodPressure) => {
                    if (bloodPressure.length > 0) {
                        const systolicAverage = bloodPressure.reduce(
                            (sum, record) => sum + record.systolic,
                            0
                        ) / bloodPressure.length;

                        const diastolicAverage = bloodPressure.reduce(
                            (sum, record) => sum + record.diastolic,
                            0
                        ) / bloodPressure.length;

                        return {
                            systolic: Math.round(systolicAverage),
                            diastolic: Math.round(diastolicAverage)
                        };
                    }

                    return null;
                })
        };
    }

    _generateGlucoseSummary(glucose) {
      // console.log('glucose', glucose);
        return { weeks: this._groupByDays(glucose) };
    }

    _generateWornDaysSummary(dailyGlucose) {
        return {
            weeks: dailyGlucose.weeks.map(
                (week) => Object.values(week).filter((day) => day !== null).length
            )
        };
    }

    _generateTimeInRangeSummary(dailyGlucose) {
        return {
            weeks: dailyGlucose.weeks.map(
                (week) => {
                    const inRangeWeek = {};

                    for (const [day, data] of Object.entries(week)) {
                        let inRangeDay;

                        if (data !== null) {
                            inRangeDay = Math.round(
                                data.filter((record) => record.value > 3.9 && record.value < 10).length /
                                data.length *
                                100
                            );
                        } else {
                            inRangeDay = null;
                        }

                        inRangeWeek[day] = inRangeDay;
                    }

                    return inRangeWeek;
                }
            )
        };
    }

    _generateExerciseSummary(exercise) {
        return { weeks: this._groupByDays(exercise) }
    }

    _generateFluidSummary(fluids) {
        return {
            weeks: this._groupByWeek(fluids).map(
                (week) => {
                    return week.reduce(
                        (result, current) => ({
                            water: result.water + current.water,
                            alcohol: result.alcohol + current.alcohol,
                            caffeine: result.caffeine + current.caffeine
                        }),
                        { water: 0, alcohol: 0, caffeine: 0 }
                    );
                }
            )
        }
    }

    _generateNutritionSummary(nutrition) {
        return { weeks: this._groupByDays(nutrition, MonthSummary.NUTRITION_DATE_KEY) };
    }
}
