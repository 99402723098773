import React from 'react';
import './dash-body.scss';
import {CContainer, CRow, CCol} from "@coreui/react";
import DashTable from "./DashTable";
import {useState} from 'react';
import CIcon from "@coreui/icons-react";
import {cilChart, cilGraph} from "@coreui/icons";
import fn from 'src/utils/Helper';
import {useSelector} from "react-redux";
import LineGraph from 'src/views/components/DashGraph/LineGraph';
import LineGraphCustom from "../../components/DashGraph/LineGraphCustom";


const DashBody = ({...props}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTable, setIsTable] = useState(true);


  const [graphType, setGraphType] = useState('weight');

  const onExpandClick = () => {
    setIsExpanded(()=>!isExpanded);
  }

  const onIconClick = (ev) => {
    setIsTable(()=>!isTable)
  }

  const onGraphTypeClick = (ev) => {
    setGraphType(ev.target.id);
  }

  return (
    <>
      <div className="DBody-wrapper">
        <div className="DBody">

          <CContainer className="DBody-container">
            <CRow className="DBody-row">
              <div className="DBody-nav-wrapper">
                <div className={'toCenter'}>
                  <div>{props.title}</div>

                  <div className={'DBody-col-indication'}></div>
                  {isExpanded ?
                    (<div className={'line'}></div>) :
                    (' ')}

                </div>
                <div className={'toRight ' + (isExpanded ? 'normal' : 'closed')}>
                  <div onClick={onExpandClick}>&#10148;</div>
                </div>

              </div>

              {(isExpanded && props.isGraph) ?
                (<CRow className="DBody-row rowToCenter">
                  <CIcon
                    onClick={onIconClick}
                    className={'DNav-nav-icon ' + (!isTable ? 'icon-active' : '') }
                    size="lg" content={cilGraph}
                  /><CIcon
                  onClick={onIconClick}
                  className={'DNav-nav-icon ' + (isTable ? 'icon-active' : '') }
                  size="lg" content={cilChart}
                />
                </CRow>) :
                (' ')}


            </CRow>

            {isExpanded ?
              (<>
                {isTable ?
                  (props.tables.map((table, idx) => (
                    <CRow className="DBody-row" key={'tabRow-'+fn.uuId()}>
                      <CCol className="DBody-col" lg="12" md="12" sm="12">
                        <DashTable
                          title={table.title}
                          header={table?.header}
                          rows={table?.rows}
                          backgroundColor={'#fdd'}
                          isSubtitle={props.isSubtitle}
                          monthSummary={props.monthSummary}
                          glucoseWeek={props.glucoseWeek}
                        />
                      </CCol>
                    </CRow>
                  ))) :
                  (
                    <div>

                      {props.isMultiple ? (
                        <div className={'DBody-graph-nav-wrapper'}>
                          <div className={'DBody-graph-nav'}>
                            <div>TYPE:</div>
                            <div className={'DBody-graph-nav-items'}>
                              <div
                                onClick={onGraphTypeClick}
                                id={'weight'}
                                className={'DBody-graph-nav-item'+ (graphType === 'weight' ? '-active' : '')}>W</div>
                              <div
                                onClick={onGraphTypeClick}
                                id={'bmi'}
                                className={'DBody-graph-nav-item'+ (graphType === 'bmi' ? '-active' : '')}>BMI</div>
                              <div
                                onClick={onGraphTypeClick}
                                id={'bloodPressure'}
                                className={'DBody-graph-nav-item'+ (graphType === 'bloodPressure' ? '-active' : '')}>BP</div>
                            </div>
                          </div>
                        </div>
                      ) : ''}


                      {(props.isMultiple && (props.data.length !== 0)) ?
                       (
                         <>

                              {(graphType === 'weight') ?
                               (
                                <LineGraph
                                title={'Weight'}
                                xLabel={'WEEKS'}
                                yLabel={'Kg'}
                                show={true}
                                data={props.data.filter(data => (data.id === 'weight'))[0]}
                                />
                               ) :
                                ''}

                              { (graphType === 'bmi') ?
                               (
                                <LineGraph
                                title={'Body Mass Index'}
                                xLabel={'WEEKS'}
                                yLabel={'index'}
                                show={true}
                                data={props.data.filter(data => (data.id === 'bmi'))[0]}
                                />
                               ) :
                                ''}

                              {(graphType === 'bloodPressure') ?
                                (<LineGraph
                                  title={'Blood Pressure'}
                                  xLabel={'WEEKS'}
                                  yLabel={''}
                                  show={true}
                                  data={props.data.filter(data => (data.id === 'bloodPressure'))[0]}
                                />)
                                :
                                ''}

                             {(false) ?
                              ('')
                              :
                              (
                                <>
                                  <LineGraph
                                title={'Number Of Days Worn'}
                                xLabel={'WEEKS'}
                                yLabel={''}
                                show={true}
                                data={props.data.filter(data => (data.id === 'days'))[0]}
                                />

                                  <div className={'DBody-tir-buttons'}>
                                      <div>
                                        <button
                                          onClick={()=>props.setGlucoseWeek(0)}
                                          className={'DBody-tir-buttons-btn '
                                            + ((props.glucoseWeek === 0) ? ('DBody-tir-buttons-btn-active') : '')}
                                        >Week 1</button>
                                        <button
                                          onClick={()=>props.setGlucoseWeek(1)}
                                          className={'DBody-tir-buttons-btn '+ ((props.glucoseWeek === 1) ? ('DBody-tir-buttons-btn-active') : '')}>Week 2</button>
                                        <button
                                          onClick={()=>props.setGlucoseWeek(2)}
                                          className={'DBody-tir-buttons-btn ' + ((props.glucoseWeek === 2) ? ('DBody-tir-buttons-btn-active') : '')}>Week 3</button>
                                        <button
                                          onClick={()=>props.setGlucoseWeek(3)}
                                          className={'DBody-tir-buttons-btn ' + ((props.glucoseWeek === 3) ? ('DBody-tir-buttons-btn-active') : '')}>Week 4</button>
                                      </div>
                                  </div>

                                  <LineGraphCustom
                                    title={'Blood Sugar Levels And Time In Range (%)'}
                                    xLabel={'DAYS'}
                                    yLabel={'RANGE'}
                                    show={true}
                                    data={props.data.filter(data => (data.id === 'tir'))[0]}
                                    //range={props.range}
                                    //rangeBottom={props.rangeBottom}
                                    tirDataSet={props.tirDataSet}
                                  />

                                  {(props.monthSummary)
                                    ? (
                                      <div style={{margin: '0 0 1rem 3rem'}}>
                                        <div style={{display: 'flex', maxWidth: '100%', justifyContent: 'space-between'}}>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[0]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[0]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer', color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[0]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[0]]+ '%') : 'n/a'}
                                            </div>
                                          </div>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[1]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[1]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer',color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[1]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[1]]+ '%') : 'n/a'}
                                            </div>
                                          </div>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[2]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[2]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer',color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[2]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[2]]+ '%') : 'n/a'}
                                            </div>
                                          </div>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[3]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[3]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer',color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[3]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[3]]+ '%') : 'n/a'}
                                            </div>
                                          </div>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[4]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[4]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer',color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[4]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[4]]+ '%') : 'n/a'}
                                            </div>
                                          </div>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[5]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[5]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer',color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[5]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[5]]+ '%') : 'n/a'}
                                            </div>
                                          </div>

                                          <div style={{minWidth: '13.5%'}}>
                                            <div style={{textAlign: 'center'}}>{fn.getDay((new Date([props.monthSummary._weekDates[props.glucoseWeek].days[6]])).getDay())}</div>
                                            <div
                                              data-day={[props.monthSummary._weekDates[props.glucoseWeek].days[6]]}
                                              onClick={props.onDayOpen}
                                              style={{cursor: 'pointer',color: '#fff', backgroundColor: '#312182', padding: '0.25rem', textAlign: 'center', margin: '1px'}}>
                                              {(props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[6]] !== null) ? (props.monthSummary.medical.timeInRange.weeks[props.glucoseWeek][props.monthSummary._weekDates[props.glucoseWeek].days[6]]+ '%') : 'n/a'}
                                            </div>
                                          </div>


                                        </div>
                                      </div>
                                    )
                                    : '' }
                                </>
                              )}
                         </>
                        ) :'' }


                      </div>
                  )}
              </>) :
              (' ')}

          </CContainer>

        </div>
      </div>



    </>
  );
};


export default DashBody;
