import React from 'react';
import './nutrition-day.scss';
import {useState} from "react";
import fn from '../../../utils/Helper';
import {useSelector} from "react-redux";
import {useEffect} from "react";
import moment from "moment";

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const mealsOf = ['breakfast', 'lunch', 'dinner', 'supper', 'snack'];

const NutritionDay = ({...props}) => {
  const nutritionIntake = useSelector(state => state.nutrition.intake);
  const mealOfDay = useSelector(state => state.mealOfDay);
  // day of the week
  // props.isExpanded
  // meal type
  const [isMeal, setIsMeal] = useState([false, false, false, false, false])
  //console.log(props.allWeeksSummary[props.nutritionWeek])
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [dailySummaryExpanded, setDailySummaryExpanded] = useState(false);
  //console.log(props)

  useEffect(()=>{

    if(props.nutrition){
      // console.log('props.nutrition', props.nutrition);
      setFilteredMeals(getDailyNutrition);
    }
  },[props.isExpanded,isMeal]);

  const getDailyNutrition = () => {
      const arrNuts = props.nutrition.filter(nut => {
        let day = 0;
        props.isExpanded.forEach((iE, i) => {
          if(iE) day = i;
        });
        let ml = 0;
        isMeal.forEach((m, i)=>{
          if(m) ml = i;
        });
        //console.log(nut);
        return ((nut.type === mealsOf[ml]) && (nut.day === days[day]))
        //console.log(days[day], mealsOf[ml]);
      })
      //console.log(arrNuts);
      return arrNuts;
  }

  const generateNavState = (day) => {
    switch(day){
      case 'monday':
        return  stateArray(0, !props.isExpanded[0]);
      case 'tuesday':
        return stateArray(1, !props.isExpanded[1]);
      case 'wednesday':
        return stateArray(2, !props.isExpanded[2]);
      case 'thursday':
        return stateArray(3, !props.isExpanded[3]);
      case 'friday':
        return stateArray(4, !props.isExpanded[4]);
      case 'saturday':
        return stateArray(5, !props.isExpanded[5]);
      case 'sunday':
        return stateArray(6, !props.isExpanded[6]);
    }
  }

  const generateMealState = (meal) => {
    switch(meal){
      case 'breakfast':
        return stateArray(0, !isMeal[0], 5);
      case 'lunch':
        return stateArray(1, !isMeal[1], 5);
      case 'dinner':
        return stateArray(2, !isMeal[2], 5);
      case 'supper':
        return stateArray(3, !isMeal[3], 5);
      case 'snack':
        return stateArray(4, !isMeal[4], 5);
    }
  }

  const stateArray = (key, value, len = 7) => {
    const arr = [];
    for(let i=0; i < len; i++){
      arr.push(false);
      if(key === i) arr[key] = value;
    }
    return arr;
  }

  const onSetIsExpanded = (ev) => {
    const day = (ev.target.id).toLowerCase();
    console.log('day', day);
    props.setIsExpanded(generateNavState(day));
  }

  const onMealClick = (ev) => {
    const meal = (ev.target.id).toLowerCase();
    console.log('meal', meal);
    setIsMeal(generateMealState(meal));
  }

  return (
    <>
      <div className={'NutritionDay-wrapper' + (props.isExpanded[props.idx] ? '-active': '')}>
        <div className={'NutritionDay'}>
          <div style={{flex: '1 1 100%'}} className={'NutritionDay-name'}>
            <div style={{display: 'flex'}}>
              {/*{console.log(props.nutrition)}*/}
              <div style={{minWidth: '100px'}}>{props.day}</div>
              <div style={{display: 'flex', justifyContent: 'center', flex: '1 1 100%'}}>
                {/*(props.allWeeksSummary) ? (props.allWeeksSummary[props.nutritionWeek]) : ''*/}
                {(props.dayData) ? (<div>{props.dayData.calories} calories</div>) : (<div>no data</div>)}
              </div>
            </div>
          </div>
          <div
            id={props.day}
            onClick={onSetIsExpanded}
            className={'NutritionDay-navBtn' + (props.isExpanded ? '-active' : '')}>
            &#10148;
          </div>
        </div>
      </div>
      {(props.isExpanded[props.idx] && props.nutrition) ?
        (<>
          <div className={'NutritionDay-content'}>

            {mealOfDay.map((meal,idx) => (
              <div key={'mealOfDay-'+fn.uuId()}>

                <div className={'NutritionDay-content-nav' + (isMeal[idx] ? '-active' : '')}>
                  <div>
                    {meal}
                  </div>
                  <div
                    id={meal}
                    onClick={onMealClick}
                    className={'NutritionDay-content-nav-sub' + (!isMeal[idx] ? '-active' : '')}>
                    &#10148;
                  </div>
                </div>
                {isMeal[idx] ?
                  (<>
                    <div className={'NutritionDay-table-wrapper'}>
                      <div className={'NutritionDay-table'}>
                        <table>
                          <thead>
                          <tr>
                            <th>Name of Food</th>
                            <th>Calories</th>
                            <th>Fat</th>
                            <th>Saturated</th>
                            <th>Protein</th>
                            <th>Sugar</th>
                            <th>Time</th>
                          </tr>
                          </thead>
                          <tbody>

                          {props.nutrition.filter(it => {

                          })}
                          {/*console.log(filteredMeals)*/}
                          {filteredMeals.map(item => {
                            //console.log(item)
                              return (
                                <tr key={'intake-'+fn.uuId()}>
                                  <td>{item.title}</td>
                                  <td>{item.calories}</td>
                                  <td>{item.fat}</td>
                                  <td>{item.satFat}</td>
                                  <td>{item.protein}</td>
                                  <td>{item.sugar}</td>
                                  <td>{item.hour}:{item.min}</td>
                                </tr>
                              )


                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>) :
                  ('')}

              </div>
            ))}


        </div>
          {(props.dayData) ?
            (
              <div>
                <div
                  className={'summary' + ((dailySummaryExpanded) ? ('-expand') : '')}
                  style={{color: '#000',display:'flex', justifyContent: 'space-between', padding: '0.25rem 1.75rem 0.25rem 1.25rem'}}>
                  <div style={{flex: '1 1 100%', padding: '0.25rem'}}>Daily Summary</div>

                  <div
                    id={props.day}
                    style={{color: '#000'}}
                    onClick={()=>setDailySummaryExpanded((prev)=> !prev)}
                    className={'summary' + ((dailySummaryExpanded) ? '-inactive' : ('-active'))}>
                    &#10148;
                  </div>
                </div>

                {(dailySummaryExpanded) ?
                  (
                    <div>

                      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <div style={{minWidth: '500px', margin: '1rem auto'}}>
                          <h4 style={{textAlign: 'center'}}>MACROS</h4>

                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>Calories:</div><div>{props.dayData.calories}</div>
                          </div>

                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>Carbohydrate:</div><div>{props.dayData.carbohydrate}</div>
                          </div>

                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>Fat:</div><div>{props.dayData.fat}</div>
                          </div>

                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>Saturated Fat:</div><div>{props.dayData.saturated_fat}</div>
                          </div>

                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>Protein:</div><div>{props.dayData.protein}</div>
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                  : ''}


              </div>
            )
            :
            (<div>No data</div>)}
        </>) :
        ''
      }
    </>
  );
};


export default NutritionDay;
