import React from 'react';
import {CCol, CContainer, CRow} from "@coreui/react";
import fn from "../../../utils/Helper";
import './dash-table.scss';
import moment from 'moment';

const DashTable = ({...props}) => {

  let weekKeys = null;
  let weekData = null;
  if(props.monthSummary?.medical?.glucose?.weeks[props.glucoseWeek]){
    weekKeys = Object.keys(props.monthSummary?.medical?.glucose?.weeks[props.glucoseWeek]);
    weekData = props.monthSummary?.medical?.glucose?.weeks[props.glucoseWeek];
  }

  return (
    <>
      <CContainer className={'DashTable'}>
        <CRow className={'DashTable-row'}>
          <CCol
            className={'DashTable-col'}
            lg={'12'} md={'12'} sm={'12'}
          >
            {props.isSubtitle ?
              (<div className={'DashTable-col-title'}>
                <div>{props.title}</div>
                <div className={'DashTable-col-indication'}></div>
                <div className={'DashTable-col-indication-wrapper'}>
                  {/*<div className={'DashTable-col-indication'}></div>*/}
                </div>
              </div>) :
              ''}
            <div className={'DashTable-table-wrapper'}>
              <table className={'DashTable-table'}>
                <thead>
                <tr>
                  {props.header.map((h, i) => (
                    <th key={'thead-' + fn.uuId()}>
                      {(h.title && h.title.toLowerCase().includes('day') ?
                        (weekKeys[i - 1] !== undefined ? (
                              weekData[weekKeys[i - 1]] && weekData[weekKeys[i - 1]].length > 0 ?
                                moment(weekData[weekKeys[i - 1]][0].created_at).format('ddd')
                                : 'N/A'
                            ) :
                            'n/a'
                        ) : h.title)}
                    </th>))}
                </tr>
                </thead>
                <tbody>
                {props.rows.map((row, idx) => (<tr key={'row-' + fn.uuId()}>
                  {row.map((item) => (
                    <td style={(idx % 2 === 0) ? {backgroundColor: props.backgroundColor} : {}}
                        key={'tbody-' + fn.uuId()}>{item}</td>
                  ))}
                </tr>))}
                </tbody>
              </table>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </>
  );
};


export default DashTable;
