import React from 'react';
import './dash-notes.scss';
import CIcon from "@coreui/icons-react";
import {cilPencil} from "@coreui/icons";
import {useEffect} from "react";
import {useState} from "react";
import fn from 'src/utils/Helper';
import {CContainer, CRow, CCol} from "@coreui/react";
import {useHistory} from "react-router-dom";
import cf from '../../../config';
import moment from 'moment';

const DashNotes = ({...props}) => {

  const [userNotes, setUserNotes] = useState([]);
  const [updateNotes, setUpdateNotes] = useState(false);
  const history = useHistory();
  const [currentNote, setCurrentNote] = useState('');

  // useEffect(()=>{
  //   const arr = [];
  //   if(props.notes){
  //     //console.log(JSON.parse(props.notes.value));
  //     try{
  //       setUserNotes(JSON.parse(props.notes.value));
  //     }catch(err){
  //       console.log(err);
  //     }
  //
  //   }
  // },[props.notes]);

  // read notes for user
  useEffect(()=>{
    const lastUser = JSON.parse(localStorage.getItem('lastProfile'));
    const token = JSON.parse(localStorage.getItem('bearerToken'));
    if(lastUser && token){
      fetch(cf.API_BASE + `/user/notes/${lastUser.id}`,{
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        }
      }).then(res => res.json())
        .then(res => {
          console.log(res.notes);
          const notesArr = sortNotes(res.notes);
          setUserNotes(notesArr);
        }).catch(err => {
          console.log(err);
      });
    }
  },[]);

  const onTextAreaChange = (ev) => {
    setCurrentNote(ev.target.value);
  }

  // should add new note && clear current_note
  // {user_id, content}
  const createNote = (ev) => {
    if(currentNote.length < 1) return;
    const lastUser = JSON.parse(localStorage.getItem('lastProfile'));
    const token = JSON.parse(localStorage.getItem('bearerToken'));
    if(lastUser && token){
      fetch(`${cf.API_BASE}/user/notes`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          user_id: lastUser.id,
          content: currentNote
        })
      }).then(res => res.json())
        .then(res => {
          console.log(res);
          let notesArr = updateUserNotes(res.note);
          notesArr = sortNotes(notesArr);
          setUserNotes(notesArr);
        }).catch(err => {
          console.log(err);
      });
    }
  }

  const updateUserNotes = (note) => {
    setCurrentNote('');
    const notes = userNotes.filter(nt => nt.id !== note.id);
    notes.push(note);
    return notes;
  }

  const sortNotes = (notesArr) => {
    notesArr.sort((a, b) => {
      const after = moment(a.created_at * 1000).isAfter(moment(b.created_at * 1000));
      const before = moment(a.created_at * 1000).isBefore(moment(b.created_at * 1000));
      return after ? -1 : (before ? 1 : 0);
    })
    return notesArr;
  }

  // add new message and make current one edited - it will be stroked (current_note represents comment for the note as the same field is used)
  // after adding comment update local state (full note with comments is returned so replace note only )
  // {note_id, content}
  const addComment = (msg) => {
    if(currentNote === '') return;
    const token = JSON.parse(localStorage.getItem('bearerToken')) || null;
    console.log(msg);
    // publish comment for message and strikethrough message (edited = true)
    if(token){
      fetch(`${cf.API_BASE}/user/notes/comment`,{
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token
        },
        body: JSON.stringify({
          note_id: msg.id,
          content: currentNote
        })
      }).then(res => res.json())
        .then(res => {
          console.log(res);
          let userNotesArr = updateUserNotes(res.note);
          userNotesArr = sortNotes(userNotesArr);
          setUserNotes(userNotesArr);
        }).catch(err => {
          console.log(err);
      })
    }

  }


  return (
    <>

          <CContainer style={{border: '2px solid #bbb'}}>
            <CRow>

              <CCol className="DBody-col" lg="12" md="12" sm="12">

                <div className={'dashNotes'}>
                  <h3>NOTES</h3>
                  <div className={'dashNotes-indication-wrapper'}>
                    <div
                    style={{maxWidth: '70px', margin: '0 auto'}}
                    className={'dashNotes-indication'}/>
                  </div>

                  <div style={{margin: '0.5rem', display: 'flex', justifyContent: 'center'}}>
                    <ul style={{minWidth: '300px'}}>
                      {(userNotes) ? (
                        userNotes.map(note => (
                          <div key={'note-' + note.id}>
                            <li
                            style={{...flexBt}}
                          >
                            <div>
                              <small style={{display: 'block', width:100}}>{moment(note.updated_at * 1000).format('DD MMM YYYY')}</small>
                              <small style={{display: 'block', width:100}}>{moment(note.updated_at * 1000).format('dddd HH:mm')}</small>
                            </div>

                            <div>{note.admin.name}</div>

                            <div style={{...flexBt,...w100}}>
                              <div style={{...max400p, ...(note.comments.length !== 0 ? strike : {})}}>{note.content}</div>
                              <button
                                style={{...btn, ...flexCenter, ...posRel, ...m3}}
                                onClick={()=>addComment(note)}
                              >
                                <div style={{...plus}}>+</div>
                              </button>
                            </div>
                          </li>
                            <ul style={{...commentsSection}}>
                              {note.comments.map((comment, idx) => (
                                <li key={'comment-' + comment.id}>
                                <div style={{...flexBt}}>
                                  <div style={{...(idx < note.comments.length - 1 ? strike : {})}}> {comment.content}</div>
                                  <div>
                                    <div>
                                      <small>by {comment.admin.name}
                                        on {moment(comment.created_at * 1000).format('DD-MMM-YYYY HH:mm')}
                                      </small></div>
                                  </div>
                                </div>
                              </li>
                              ))}
                            </ul>
                          </div>
                          )
                        )
                      ) : ''}

                      <textarea
                        cols={40}
                        rows={5}
                        onChange={onTextAreaChange}
                        value={currentNote ?? ''}
                        style={{marginBottom: '1rem', resize: 'none', ...w100}}
                      />

                      <button
                        style={{margin: '0 auto'}}
                        onClick={createNote}
                        className={'dashNotes-button'}>
                        <CIcon size={'sm'} content={cilPencil}/>
                        <span>Add a Note</span>
                      </button>

                    </ul>
                  </div>

                </div>

              </CCol>

            </CRow>
          </CContainer>

    </>
  );
};


export default DashNotes;

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const posRel = {
  position: 'relative',
}

const plus = {
  fontSize: '1.5rem',
  fontWeight: 'bold',
  position: 'absolute',
  top: -10,
  left: 2,
  cursor: 'pointer',
}

const flexBt = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const m3 = {
  margin: '0.5rem',
}

const btn = {
  borderRadius: '50%',
  width: '24px',
  height: '24px',
}

const w100 = {
  width: '100%',
}

const commentsSection = {
  margin: '1rem 0.25rem 2rem 0.25rem',
  paddingLeft: '2rem',
}

const strike = {
  textDecoration: 'line-through',
}

const max400p = {
  maxWidth: 400,
}

const bd = {
  border: '1px solid red',
}
