import React, {useState} from 'react';
import './dash-nutritions.scss';
import {CContainer, CRow, CCol} from "@coreui/react";
import DashNutritionNav from "./DashNutritionNav";
import fn from 'src/utils/Helper';
import {useEffect} from "react";

const DashNutritions = ({...props}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [allWeeksSummary, setAllWeeksSummary] = useState(null);
  //console.log(props, fn.getMonthLength(props.date))
  const onExpandClick = () => {
    setIsExpanded(()=>!isExpanded);
  }

  useEffect(()=>{

    if(props.nutrition && props.date){
      const allWeeksNutrition = [];
      //console.log(props.monthSummary.nutrition.weeks[week]);
      //console.log(props.monthSummary.nutrition.weeks[1])
      for(let w=0; w<5;w++){
        const weeklyNutrition = {};
        for (const [key, value] of Object.entries(props.monthSummary.nutrition.weeks[w])) {
          //console.log(props.monthSummary.nutrition);
          weeklyNutrition[key] = value !== null
            ? value.reduce((result, nutrition) => ({
              calories: result.calories + nutrition.calories,
              eaten_at: nutrition.eaten_at,
              createdAt: nutrition.created_at,
              carbohydrate: result.carbohydrate + nutrition.carbohydrate,
              fat: result.fat +nutrition.fat,
              fibre: result.fibre + nutrition.fibre,
              protein: result.protein + nutrition.protein,
              salt: result.salt + nutrition.salt,
              saturated_fat: result.saturated_fat + nutrition.saturated_fat,
              sugar: result.sugar + nutrition.sugar,
            }), {
              calories: 0,
              carbohydrate: 0,
              fat: 0,
              fibre: 0,
              protein: 0,
              salt: 0,
              saturated_fat: 0,
              sugar: 0
            })
            : null;
        }
        //console.log('week '+ w)
        allWeeksNutrition.push(weeklyNutrition);
      }

      setAllWeeksSummary(allWeeksNutrition);

    }
  },[props.nutrition]);

  return (
    <>
      <div className="DBody-wrapper">
        <div className="DBody">
          <CContainer className="DNutrition-container">
            <CRow className="DNutrition-row">
              <div className="DNutrition-nav-wrapper">
                <div className={'toCenter'}>

                  <div>NUTRITION</div>

                  <div className={'DNutrition-col-indication'}></div>
                  {isExpanded ?
                    (<div className={'line'}></div>) :
                    (' ')}

                </div>

                <div className={'toRight ' + (isExpanded ? 'normal' : 'closed')}>
                  <div onClick={onExpandClick}>&#10148;</div>
                </div>

              </div>
            </CRow>
            {isExpanded ?
              (<CRow>
                <CCol lg={'12'} md={'12'} sm={'12'}>
                  <DashNutritionNav
                    isGraphNavOn={true}
                    isExpanded={isExpanded}
                    nutrition={props.nutrition}
                    date={props.date}
                    setDate={props.setDate}
                    setNutritionWeek={props.setNutritionWeek}
                    nutritionWeek={props.nutritionWeek}
                    allWeeksSummary={allWeeksSummary}
                  />
                </CCol>
              </CRow>) :
              (' ')}

          </CContainer>
        </div>
      </div>


    </>
  );
};


export default DashNutritions;
