import { createStore } from 'redux'
import credentials from './config';
import cf from './config';
import {v4 as uuid} from 'uuid';


function genRandom(min = 1, max = 100, isArray = 0, isString = false, inOrder = false){
  const arr = [];
  if(isArray){
    for(let i=0; i < isArray; i++){
      if(isString) arr.push('');
      else if(!isString && inOrder) arr.push(i+1);
      else arr.push(Math.round((Math.random() + min) * max));
    }
    return arr;
  }
  return Math.round((Math.random() + min) * max);
}

const initialState = {
  isPresentationActive: false,
  systemNotificationsRequest: 0,
  systemNotifications: [],
  isDebug: false,
  notifications:[],
  notificationTimeouts: [],
  blurredPanel: false,
  userManagement: {
    selectedUser: null,
    selectedGroup: [],
    userAbilities: [],
    selectedUserAbility: null,
    selectedGroupAbility: null,
    users: [],
    groups: [],
    abilities: [],
    groupAbilities: [],
    userIndex: -1,
    groupAbilityIndex: -1,
    flashMessage: {text: '', style: {}},
    formFields: {
      User: [
        {id: 1, name: 'name', type:'text'}, {id:2, name: 'email', type:'email'}, {id:4, name: 'height_cm', type:'number'}, {id:5, name:'is_diabetic', type:'number', min:0, max:1}
      ],
      AdminUser: [
        {id: 1, name: 'name', type:'text'}, {id:2, name: 'email', type:'email'}
      ],
      UserEvent: [
        {id:1, name: 'username', type:'text'}, {id:2, name: 'first_name', type:'text'}, {id:3, name: 'surname', type:'text'}, {id:4, name: 'email', type:'email'},
      ],
    },
    userInput: null,
  },
  selectedExercise: null,
  exerciseDifficulties: [],
  exerciseTypes: [],
  exerciseMuscles: [],
  exerciseEquipments: [],
  exerciseImages: [],


  sessionCanBeStarted: false,
  isAuthenticated: false,
  credentials: credentials,
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  usersData: [
    {id: 0, username: 'John Doe', registered: '2018/01/01', role: 'Guest', status: 'Pending', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
    email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 1, username: 'Samppa Nori', registered: '2018/01/01', role: 'Member', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 2, username: 'Estavan Lykos', registered: '2018/02/01', role: 'Staff', status: 'Banned',content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 3, username: 'Chetan Mohamed', registered: '2018/02/01', role: 'Admin', status: 'Inactive', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 4, username: 'Derick Maximinus', registered: '2018/03/01', role: 'Member', status: 'Pending', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 5, username: 'Friderik Dávid', registered: '2018/01/21', role: 'Staff', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 6, username: 'Yiorgos Avraamu', registered: '2018/01/01', role: 'Member', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 7, username: 'Avram Tarasios', registered: '2018/02/01', role: 'Staff', status: 'Banned', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 8, username: 'Quintin Ed', registered: '2018/02/01', role: 'Admin', status: 'Inactive', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 9, username: 'Enéas Kwadwo', registered: '2018/03/01', role: 'Member', status: 'Pending', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 10, username: 'Agapetus Tadeáš', registered: '2018/01/21', role: 'Staff', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 11, username: 'Carwyn Fachtna', registered: '2018/01/01', role: 'Member', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 12, username: 'Nehemiah Tatius', registered: '2018/02/01', role: 'Staff', status: 'Banned', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 13, username: 'Ebbe Gemariah', registered: '2018/02/01', role: 'Admin', status: 'Inactive', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 14, username: 'Eustorgios Amulius', registered: '2018/03/01', role: 'Member', status: 'Pending', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 15, username: 'Leopold Gáspár', registered: '2018/01/21', role: 'Staff', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 16, username: 'Pompeius René', registered: '2018/01/01', role: 'Member', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 17, username: 'Paĉjo Jadon', registered: '2018/02/01', role: 'Staff', status: 'Banned', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 18, username: 'Micheal Mercurius', registered: '2018/02/01', role: 'Admin', status: 'Inactive', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 19, username: 'Ganesha Dubhghall', registered: '2018/03/01', role: 'Member', status: 'Pending', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 20, username: 'Hiroto Šimun', registered: '2018/01/21', role: 'Staff', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 21, username: 'Vishnu Serghei', registered: '2018/01/01', role: 'Member', status: 'Active', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 22, username: 'Zbyněk Phoibos', registered: '2018/02/01', role: 'Staff', status: 'Banned', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 23, username: 'Aulus Agmundr', registered: '2018/01/01', role: 'Member', status: 'Pending', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'},
    {id: 42, username: 'Ford Prefect', registered: '2001/05/25', role: 'Alien', status: 'Don\'t panic!', content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
      email: 'user@email.com', phone: genPhone(), address1: 'address1', address2: 'address2', city: getCity(), state: 'state', postcode: 'post-code'}
  ],
  links: [
  ],
  callLogs: [
    {id: 0, uId: '#1234567', name: 'John Doe', time: getRandomInterval()},
    {id: 1, uId: '#7543210', name: 'Samppa Nori', time: getRandomInterval()},
    {id: 2, uId: '#1233537', name: 'Estavan Lykos', time: getRandomInterval()},
    {id: 3, uId: '#1114567', name: 'Chetan Mohamed', time: getRandomInterval()},
    {id: 4, uId: '#1222567', name: 'Derick Maximinus', time: getRandomInterval()},
    {id: 5, uId: '#1233337', name: 'Friderik Dávid', time: getRandomInterval()},
  ],
  messages: [
    {id:0, isUrgent: false, from:'Ebbe Gemariah', to: 'To User', timestamp: getRandomTimestamp(), content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'},
    {id:1, isUrgent: false, from:'this user', to: 'To User', timestamp: getRandomTimestamp(), content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'},

  ],
  graphs: {
    pieData: {
      labels: ['Carbohydrates(%)', 'Protein(%)', 'Fats(%)'],
      datasets: [
        {
          label: '# of Votes',
          data: [65, 25, 10],
          backgroundColor: [
            '#ec9e30',
            '#3b78b1',
            '#ef524b',
          ],
          borderColor: [
            '#ec9e30',
            '#3b78b1',
            '#ef524b',
          ],
          borderWidth: 1,
        },
      ],
    },
    lineGraph: {
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            //position: 'top',
          },
          title: {
            display: false,
            text: 'Title',
          },
          lineWidth: 1,
        },
       scales: {
          y: {
            title: {
              display: true,
              text: 'UNITS'
            },
            ticks:{
              callback: function(value, index, ticks){
                return value;
              }
            }
          }
       }
      },
      data: {
        //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        labels: genRandom(1,10,10,false, true),
        datasets: [
          {
            id: 'carbo',
            label: 'Carbohydrates',
            data: genRandom(0, 10, 20),
            borderColor: '#ec9e30',
            backgroundColor: 'rgba(255, 99, 132, 1)',
          },
          {
            id: 'sugar',
            label: 'Sugar',
            data: genRandom(-1, 7, 20),
            borderColor: '#ef5248',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'saturated',
            label: 'Saturated',
            data: genRandom(3, 9, 20),
            borderColor: '#84987e',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'salt',
            label: 'Salt',
            data: genRandom(-2, 10, 20),
            borderColor: '#312182',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'weight',
            label: 'Weight',
            data: genRandom(0, 10, 20),
            borderColor: '#000',
            backgroundColor: 'rgba(255, 99, 132, 1)',
          },
          {
            id: 'waist',
            label: 'Waist',
            data: genRandom(-1, 7, 20),
            borderColor: '#000',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'bmi',
            label: 'BMI',
            data: genRandom(3, 9, 20),
            borderColor: '#000',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'bloodPressure',
            label: 'Blood Pressure',
            data: genRandom(-2, 10, 20),
            borderColor: '#000',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'freq',
            label: 'Frequency',
            data: genRandom(0, 10, 20),
            borderColor: '#408228',
            backgroundColor: 'rgba(255, 99, 132, 1)',
          },
          {
            id: 'intense',
            label: 'Intensity and type',
            data: genRandom(-1, 7, 20),
            borderColor: '#408228',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'time',
            label: 'Time',
            data: genRandom(3, 9, 20),
            borderColor: '#408228',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'calories',
            label: 'Calories Burnt',
            data: genRandom(-2, 10, 20),
            borderColor: '#408228',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
          {
            id: 'fluids',
            label: 'Fluids',
            data: genRandom(-2, 10, 20),
            borderColor: '#003228',
            backgroundColor: 'rgba(53, 162, 235, 1)',
          },
        ],
      }
    }
  },
  tab: {
    medical: [
      {
        title: 'Anthropometrics',
        header:  [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
        rows: [
          ['Carbohydrates (g)', '45','', '',''], ['Sugar(g)', '30', '','',''], ['Saturated Fat(%)', '20', '','',''],
          ['Salt (g)', '6', '','',''], ['Fibre(g)', '30', '','',''],
        ],
      },
      {
        title: 'Number of days worn',
        header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
        rows: [
          ['Days (unit)', '45','22','23','24'],
        ]
      },
      {
        title: 'Time in Range',
        header: [{title: ''}, {title: 'DAY 1'}, {title: 'DAY 2'}, {title: 'DAY 3'},
          {title: 'DAY 4'}, {title: 'DAY 5'}, {title: 'DAY 6'}, {title: 'DAY 7'}],
        rows: [
          ['Week 1 (unit)', '45','22','23','24', '45','22','23'],
          ['Week 2 (unit)', '45','22','23','24', '45','22','23'],
          ['Week 3 (unit)', '45','22','23','24', '45','22','23'],
          ['Week 4 (unit)', '45','22','23','24', '45','22','23'],
        ]
      },
    ],
    exercise : [
      {
        title: 'Exercise',
        header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
        rows: [
          ['Sessions', '45','35','23','17'],
          ['Minutes', '30','19','15','11'],
        ]
      }
    ],
    fluids : [
      {
        title: 'Fluids',
        header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
        rows: [
          ['Water (litres)', '45','35','23','17'],
          ['Alcohol (units)', '45','35','23','17'],
          ['Caffeine (units)', '45','35','23','17'],

        ]
      }
    ]
  },
  tables: [
     {
      header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
      rows: [
        ['Weight (unit)', '45','22','23','24'],
        ['BMI (unit)', '30','32','33','35'],
        ['Blood Pressure (unit)', '20','21','17','11'],
      ]
    },
     {
      header: [{title: ''}, {title: 'WEEK 1'}, {title: 'WEEK 2'}, {title: 'WEEK 3'},{title: 'WEEK 4'}],
      rows: [
        ['Activity (unit)', '45','','',''],
        ['Measurement (unit)', '30','','',''],
        ['Value (unit)', '20','','',''],
      ]
    }
  ],
  nutrition: {
    intake: [
        { date: '2020-02-13', time: '09:00', type: 'Breakfast',
          name: 'Item 1', calories: '1020'},
        { date: '2020-02-13', time: '19:00', type: 'Dinner',
          name: 'Item 2', calories: '1020'},
        { date: '2020-02-13', time: '08:00', type: 'Breakfast',
          name: 'Item 3', calories: '1020'},
        { date: '2020-02-13', time: '07:00', type: 'Snacks',
          name: 'Item 4', calories: '1020'},
        { date: '2020-02-13', time: '15:00', type: 'Lunch',
          name: 'Item 5', calories: '1020'},
    ]
  },
  emails: [
    {createdAt: '2022/01/01', subject: 'Email Subject 1', content: 'Email Content 1'},
    {createdAt: '2022/01/01', subject: 'Email Subject 2', content: 'Email Content 2'},
    {createdAt: '2022/01/01', subject: 'Email Subject 3', content: 'Email Content 3'},
    {createdAt: '2022/01/01', subject: 'Email Subject 4', content: 'Email Content 4'},
    {createdAt: '2022/01/01', subject: 'Email Subject 5', content: 'Email Content 5'},
    {createdAt: '2022/01/01', subject: 'Email Subject 6', content: 'Email Content 6'},
    {createdAt: '2022/01/01', subject: 'Email Subject 7', content: 'Email Content 7'},
    {createdAt: '2022/01/01', subject: 'Email Subject 8', content: 'Email Content 8'},
    {createdAt: '2022/01/01', subject: 'Email Subject 9', content: 'Email Content 9'},
    {createdAt: '2022/01/01', subject: 'Email Subject 10', content: 'Email Content 10'},
    {createdAt: '2022/01/01', subject: 'Email Subject 11', content: 'Email Content 11'},
    {createdAt: '2022/01/01', subject: 'Email Subject 12', content: 'Email Content 12'},
    {createdAt: '2022/01/01', subject: 'Email Subject 13', content: 'Email Content 13'},

  ],
  daysOfTheWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  mealOfDay: ['Breakfast', 'Lunch', 'Dinner', 'Supper', 'Snack'],
  token: null,
  bearerToken: null,
  users: JSON.parse(localStorage.getItem('usersData')),
  archivedVideos: [],
}
// -------------------------------------------- ACTIONS ----------------------------------------------------------------------
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'setBlurredPanel':
      return setBlurredPanel(state, rest);
    case 'setSelectedUser':
      return setSelectedUser(state, rest);
    case 'setSelectedGroup':
      return setSelectedGroup(state, rest);
    case 'setUserAbilities':
      return setUserAbilities(state, rest);
      case 'setSelectedUserAbility':
        return setSelectedUserAbility(state, rest);
    case 'setSelectedGroupAbility':
      return setSelectedGroupAbility(state, rest);
    case 'setUsersUM':
      return setUsersUM(state, rest);
    case 'setGroups':
      return setGroups(state, rest);
    case 'setUserInput':
      return setUserInput(state, rest);
    case 'setAbilities':
      return setAbilities(state, rest);
    case 'setGroupAbilities':
      return setGroupAbilities(state, rest);
    case 'setUserIndex':
      return setUserIndex(state, rest);
    case 'setFlashMessage':
      return setFlashMessage(state, rest);


    case 'set':
      return {...state, ...rest }
    case 'addGeneratedLink':
      //console.log(state, rest);
      return {...state, ...rest }
    case 'changeUserData':
      return {...state, ...rest}
    case 'deleteUser':
      return deleteUser(state, rest)
    case 'sendMessage':
      return sendMessage(state, rest)
    case 'regenerate':
      return regenerate(state)
    case 'regenerateToken':
      return regenerateToken(state)
    case 'generateSession':
      return generateSession(state, rest)
    case 'storeBearerToken':
      return storeBearerToken(state, rest)
    case 'storeUsers':
      return storeUsers(state, rest)
    case 'setAuthenticated':
      return setAuthenticated(state, rest)
    case 'setSessionCanBeStarted':
      return setSessionCanBeStarted(state, rest)
    case 'setGroupAbilityIndex':
      return setGroupAbilityIndex(state, rest)
    case 'setSelectedExercise':
      return setSelectedExercise(state, rest)
    case 'setExerciseDifficulties':
      return setExerciseDifficulties(state, rest)
    case 'setExerciseTypes':
      return setExerciseTypes(state, rest)
    case 'setExerciseMuscles':
      return setExerciseMuscles(state, rest)
    case 'addNotification':
      return addNotification(state, rest)
    case 'removeNotification':
      return removeNotification(state, rest)
    case 'setExerciseEquipments':
      return setExerciseEquipments(state, rest)
    case 'setExerciseImages':
      return setExerciseImages(state, rest);
    case 'setIsPresentationActive':
      return setIsPresentationActive(state, rest);

    /*case 'addVideo':
      return addVideo(state, rest)
    case 'clearVideos':
      return clearVideos(state)*/
    case 'setSystemNotificationsRequest':
      return setSystemNotificationsRequest(state, rest);
    case 'removeSystemNotification':
      return removeSystemNotification(state, rest);
    case 'addSystemNotification':
      return addSystemNotification(state, rest);
    case 'setSystemNotifications':
      return setSystemNotifications(state, rest)
    case 'clearNotifications':
      return clearNotifications(state, rest);
    case 'clearTimeouts':
      return clearTimeouts(state, rest);
    case 'updateState':
      return updateState(state, rest);
    default:
      return state
  }
}
// -------------------------------------------- END OF ACTIONS ----------------------------------------------------------------------
/*
function clearVideos(state){
  state.archivedVideos = [];
  return state;
}

function addVideo(state, rest){
  const tmp = state.archivedVideos;
  tmp.push(rest.payload);
  state.archivedVideos = tmp;
  return state;
}*/
function setAuthenticated(state, rest){
  state.isAuthenticated = rest.payload;
  return state;
}

function storeUsers(state, rest){
  state.users = rest.users;
  return state;
}

function storeBearerToken(state, rest){
  state.token = rest.payload;
  return state;
}

function setSessionCanBeStarted(state, rest){
  state.sessionCanBeStarted = rest.payload;
  return state;
}

function regenerateToken(state){
  cf.getToken().then(res => res.json())
    .then(res => {
      localStorage.setItem('bearerToken', JSON.stringify(res.token));
    });
  state.token = JSON.parse(localStorage.getItem('bearerToken'));
  return state;
}

function generateSession(state, rest){
  //const token = state.token;
  const token = JSON.parse(localStorage.getItem('bearerToken')) || null;
  const participantId = rest.payload.addData.participantId;
  const sendEmail = rest.payload.addData.sendEmail;
  const urlPattern = rest.payload.addData.urlPattern;
  cf.getVideoChatId({token: token, participantId, sendEmail, urlPattern }).then(res => res.json())
    .then(res => {
      cf.fetchSession(res.video_chat.id).then(res => res.json())
        .then(res => {
          if(res.error) window.location.replace('/login');
          localStorage.setItem('chatToken', JSON.stringify(res.video_chat.token));
          const id = state.links.length;
          const href = cf.LINK_BASE + res.video_chat.id;
          const obj = {...{bearerToken: token},...{id, href, videoChat: res.video_chat}};
          //console.log(obj);
          const toSave = JSON.stringify(obj);
          localStorage.setItem('videoChat', toSave);
          rest.payload.composeLink();
        }).catch(err => {
          console.log(err);
          window.location.replace('/login')
        });
    }).catch(err => {
      console.log(window.location);
      window.location.replace('/login')
      console.log(err);
    })
  return state;
}

function regenerate(state){

    cf.getToken(cf.user).then(res => res.json())
      .then(res => {
        const token = res.token;
        if(token) localStorage.setItem('bearerToken', JSON.stringify(token));
          cf.getVideoChatId({token: token}).then(res => res.json())
            .then(res => {
              //console.log(res);
              cf.fetchSession(res.video_chat.id).then(res => res.json())
                .then(res => {
                  if(res) localStorage.setItem('chatToken', JSON.stringify(res.video_chat.token));
                  const id = state.links.length;
                  const href = cf.LINK_BASE + res.video_chat.id;
                  const obj = {...{bearerToken: token},...{id, href, videoChat: res.video_chat}};
                  //console.log(obj);
                  const toSave = JSON.stringify(obj);
                  localStorage.setItem('videoChat', toSave);

                }).catch(error => console.log(error));
            }).catch(error => console.log(error));

      }).catch(error => console.log(error));


  state.token = JSON.parse(localStorage.getItem('bearerToken')) ?? ' ';
  return state;
}

function sendMessage(state, rest){
  const arr = [];
  state.messages.forEach(msg => {
    arr.push(msg);
  })
  arr.push(rest.newMsg);
  return {...state, ...{messages: arr}};
}

function deleteUser(state, rest){
  const stateUsersData = state.usersData.filter(user => user.id !== rest.user.id);
  return {...state, ...{usersData: stateUsersData}};
}

const store = createStore(changeState)
export default store

function getRandomTimestamp(callback = genDigit){
  return Date.now() - ((Math.random()* 1000 * 60 * 60)+1) * callback();
}

function getRandomInterval(callback = genDigit){
  const date1 = Date.now() - ((Math.random()* 1000 * 60 * 60)+1) * callback();
  const date2 = Date.now() - ((Math.random()* 1000 * 60 * 60)+1) * callback();
  if(date1 < date2) return {timeStart: date1, timeEnd: date2};
  else return {timeStart: date2, timeEnd: date2};
}

function genDigit(){
  return Math.floor(Math.random()*10 + 1);
}

function genNumbersAsString(length = 1){
  let string = '';
  for(let idx = 0; idx < length; idx++){
    string += genDigit().toString();
  }
  return string;
}

function genPhone(){
  let phone = '0';
  phone += genNumbersAsString(2);
  phone += '-';
  phone += genNumbersAsString(3);
  phone += '-';
  phone += genNumbersAsString(4);
  return phone;
}


function getCity(){
  const cities = ['London', 'Dublin', 'Warsaw', 'Berlin', 'Paris', 'Moscow', 'New York'];
  let no = 0;
  do{
    no = genDigit() - 1;
  }while(no > cities.length - 1)
  return cities[no];
}

// ------------------------------------------------------- GLOBAL -------------------------------------------------------------
function setBlurredPanel(state, rest){
  state = {...state, blurredPanel: rest.payload};
  return state;
}
// ------------------------------------------------------- USER MANAGEMENT -------------------------------------------------------------

function setSelectedUser(state, rest){
  const userManagement = {...state.userManagement, selectedUser: rest.payload};
  state = {...state, userManagement: userManagement};
  // console.log(state.userManagement);
  // state.userManagement.selectedUser = rest.payload;
  return state;
}

function setSelectedGroup(state, rest){
  const userManagement = {...state.userManagement, selectedGroup: rest.payload};
  // console.log(rest);
  state = {...state, userManagement: userManagement};
  return state;
}

function setUserAbilities(state, rest){
  const userManagement = {...state.userManagement, userAbilities: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setSelectedUserAbility(state, rest){
  const userManagement = {...state.userManagement, selectedUserAbility: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setSelectedGroupAbility(state, rest){
  const userManagement = {...state.userManagement, selectedGroupAbility: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setUsersUM(state, rest) {
  const userManagement = {...state.userManagement, users: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setGroups(state, rest) {
  const userManagement = {...state.userManagement, groups: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setUserInput(state, rest) {
  const userManagement = {...state.userManagement, userInput: rest.payload};
  state= {...state, userManagement: userManagement};
  return state;
}

function setAbilities(state, rest) {
  const userManagement = {...state.userManagement, abilities: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setGroupAbilities(state, rest) {
  const userManagement = {...state.userManagement, groupAbilities: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setUserIndex(state, rest) {
  const userManagement = {...state.userManagement, userIndex: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setGroupAbilityIndex(state, rest) {
  const userManagement = {...state.userManagement, groupAbilityIndex: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}

function setFlashMessage(state, rest) {
  const userManagement = {...state.userManagement, flashMessage: rest.payload};
  state = {...state, userManagement: userManagement};
  return state;
}
// ------------------------------------------------------- END OF USER MANAGEMENT -------------------------------------------------------------

// ------------------------------------------------------- EXERCISES -------------------------------------------------------------
function setSelectedExercise(state, rest){
  state = {...state, selectedExercise: rest.payload}
  return state;
}

function setExerciseDifficulties(state, rest){
  return {...state, exerciseDifficulties: rest.payload}
}

function setExerciseTypes(state, rest){
  return {...state, exerciseTypes: rest.payload}
}

function setExerciseMuscles(state, rest){
  return {...state, exerciseMuscles: rest.payload}
}
function setExerciseEquipments(state, rest){
  return {...state, exerciseEquipments: rest.payload}
}

function setExerciseImages(state, rest){
  return {...state, exerciseImages: rest.payload}
}

// ------------------------------------------------------- END OF EXERCISES -------------------------------------------------------------

// ------------------------------------------------------- PRESENTATION STATUS -------------------------------------------------------------
function setIsPresentationActive(state, rest){
  return {...state, isPresentationActive: rest.payload};
}
// ------------------------------------------------------- END OF PRESENTATION STATUS -------------------------------------------------------

// ------------------------------------------------------- SYSTEM NOTIFICATIONS -------------------------------------------------------------
function removeSystemNotification(state, rest){
  const notifications = state.systemNotifications.filter(notification => notification.uuid !== rest.payload.uuid);
  return {...state, systemNotifications: notifications};
}
function addSystemNotification(state, rest){
  const notifications = [...state.systemNotifications, rest.payload];
  return {...state, systemNotifications: notifications};
}
function setSystemNotifications(state, rest){
  return {...state, systemNotifications: rest.payload};
}
// ------------------------------------------------------- END OF SYSTEM NOTIFICATIONS -------------------------------------------------------------

// ------------------------------------------------------- NOTIFICATIONS -------------------------------------------------------------
function setSystemNotificationsRequest(state, rest){
  return {...state, systemNotificationsRequest: rest.payload};
}
function addNotification(state, rest){
  const notification = [rest.payload, ...state.notifications];
  state = {...state, notifications: notification};
  const notificationTimeout = setTimeout((obj = state)=>{
    changeState(obj, {type: 'clearTimeouts', payload: ""});
    changeState(obj, {type: 'clearNotifications', payload: ""});
  }, 3000);
  state.notificationTimeouts.push({id:notificationTimeout, notification: rest.payload});
  return state;

}
function removeNotification(state, rest){
  const notification = state.notifications.filter(n => n.id !== rest.payload.id);
  state = {...state, notifications:notification};
  return state;
}

function clearTimeouts(state, rest){
  state = {...state, notificationTimeouts: []};
  return state;
}
function clearNotifications(state, rest){
  state = {...state, notifications: []};
  return state;
}

function updateState(state, rest){
  // console.log(state, rest);
  state = {...state};
  return state;
}



// ------------------------------------------------------- END OF NOTIFICATIONS -------------------------------------------------------------
